
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { provideRouter } from '@angular/router';
import { routes } from './app.route'
import { DatePipe, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { FhDatePipe } from './shared/pipes/fh-date.pipe';
import { GoogleMap } from '@angular/google-maps';
import { CoreHttpInterceptor } from './core/interceptor/http-interceptor.service';
import { NgbActiveModal, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from './material-module/material-module';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { NpxFamilyHappyCommonModule } from 'npx-family-happy-common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TruncateStringPipe } from './shared/pipes/truncate-string.pipe';
import { CaregiverCardDatePipe } from './requests-handling/caregiver-scoreboard/caregiver-card/caregiver-card-pipes/caregiver-card-date.pipe';
import { CaregiverCardDistancePipe } from './requests-handling/caregiver-scoreboard/caregiver-card/caregiver-card-pipes/caregiver.card-distance.pipe';
import { BabySitterService } from './caregiver/babysitter/baby-sitter-service/baby-sitter-service.service';
import { provideQuillConfig } from 'ngx-quill/config';


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    TitleCasePipe,
    DatePipe,
    TitleCasePipe,
    CaregiverCardDatePipe,
    CaregiverCardDistancePipe,
    UpperCasePipe,
    FhDatePipe,
    TruncateStringPipe,
    GoogleMap,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'it' },
    NgbTypeaheadConfig,

    importProvidersFrom(
      AngularFireModule.initializeApp(environment.firebaseConfig),
      HttpClientModule,
      MaterialModule,
      AngularFirestoreModule,
      AngularFireStorageModule,
      NpxFamilyHappyCommonModule,
      BrowserAnimationsModule,
      NpxFamilyHappyCommonModule,
      BabySitterService,
      NgbActiveModal
    ),
  ],

};
