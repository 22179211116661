<div class="fh-form-section" style="gap: 32px" [formGroup]="form">
  <fieldset>
    <legend>Caratteristiche colf richiesta</legend>
    <div class="fh-form-grid" style="justify-items: normal">
      <div style="display: flex; flex-direction: column; gap: 10px;" formGroupName="requestedAge">
        <label class="fw-semibold">Età colf</label>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="reqage1">18-35 anni</label>
          <input type="checkbox" id="reqage1" formControlName="requestedAgeCluster1">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="reqage2">36-50 anni</label>
          <input type="checkbox" id="reqage2" formControlName="requestedAgeCluster2">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="reqage3">51+ anni</label>
          <input type="checkbox" id="reqage3" formControlName="requestedAgeCluster3">
        </div>
      </div>
      <div style="display: flex; flex-direction: column; gap: 10px;" formGroupName="requiredLanguage">
        <label class="fw-semibold">Lingue richieste</label>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="italian">Italiano</label>
          <input type="checkbox" id="italian" formControlName="italian">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="english">Inglese</label>
          <input type="checkbox" id="english" formControlName="english">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="spanish">Spagnolo</label>
          <input type="checkbox" id="spanish" formControlName="spanish">
        </div>
      </div>
      <div style="display: flex; flex-direction: column; gap: 10px;">
        <label class="fw-semibold">Disponibilità Colf</label>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="houseCleaning">Pulizia e lavori domestici</label>
          <input type="checkbox" id="houseCleaning" formControlName="houseCleaning">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="ironing">Stiro</label>
          <input type="checkbox" id="ironing" formControlName="ironing">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="cooking">Preparazione pasti</label>
          <input type="checkbox" id="cooking" formControlName="cooking">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="sewing">Cucito/ Aggiustamento abiti</label>
          <input type="checkbox" id="sewing" formControlName="sewing">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="license">Patente</label>
          <input type="checkbox" id="license" formControlName="license">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="car">Automunita</label>
          <input type="checkbox" id="car" formControlName="car">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="coliving">Disp. Convivenza</label>
          <input type="checkbox" id="coliving" formControlName="coliving">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="relocation">Disp. Trasferte</label>
          <input type="checkbox" id="relocation" formControlName="relocation">
        </div>
      </div>
      <div style="display: flex; flex-direction: column; gap: 10px;">
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;margin-top: 32px;">
          <label class="label" for="groceries">Aiuto con la spesa </label>
          <input type="checkbox" id="groceries" formControlName="groceries">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="windowCleaning">Pulizie Tapparelle/ zanzariere/ vetri</label>
          <input type="checkbox" id="windowCleaning" formControlName="windowCleaning">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="terraceCleaning">Pulizia Terrazzi</label>
          <input type="checkbox" id="terraceCleaning" formControlName="terraceCleaning">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="gardening">Giardinaggio</label>
          <input type="checkbox" id="gardening" formControlName="gardening">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="helping">Aiuto Commissioni</label>
          <input type="checkbox" id="helping" formControlName="helping">
        </div>
      </div>
    </div>
  </fieldset>
  <fieldset>
    <legend>Caratteristiche incarico</legend>
    <div class="fh-form-grid">
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="iniziostimato">Inizio stimato</label>
        <input type="date" id="iniziostimato" formControlName="starting">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="durataimpegno">La famiglia avrebbe bisogno fino a</label>
        <input type="date" id="durataimpegno" formControlName="estimatedLasting">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="serviceStart">Da quando</label>
        <input type="text" id="serviceStart" formControlName="serviceStart" readonly>
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="serviceDuration">Per quanto tempo</label>
        <input type="text" id="serviceDuration" formControlName="serviceDuration" readonly>
      </div>
      <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
        <label class="fw-semibold" for="continuousJob">Lavoro continuativo</label>
        <input type="checkbox" id="continuousJob" formControlName="continuousJob">
      </div>
      <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
        <label class="fw-semibold" for="badWorkHours">Orari scomodi - <span style="font-size: 13px; color: grey;">A
            chiamata,
            Periodo breve inferiore al mese, tra le 20 e le 7.30; solo sabato o Domenica; meno o uguale 3 ore/ al
            giorno</span></label>
        <input type="checkbox" id="badWorkHours" formControlName="badWorkHours">
      </div>
      <div class="fh-form-input-field" style="grid-template-columns: 1fr;">
        <label class="fw-semibold" for="descrizioneimpegno">Descrizione impegno - <span
            style="font-size: 13px; color: grey;">"Serve una colf per..."</span></label>
        <textarea rows="6" type="text" id="descrizioneimpegno" maxlength="2048"
          formControlName="requestDescription"></textarea>
      </div>
    </div>
  </fieldset>
  @if (inputData !== undefined) {
    <app-request-pricing [province]="provincia" [badWorkHoursInput]="form.get('badWorkHours')!.value" [handicapInput]="false" [moreThanOneInput]="false"
    [request]="inputData" [reqService]="reqService" (pricing)="updatePricing($event)"></app-request-pricing>
  }
  <fieldset>
    <legend>Orari di lavoro</legend>
    <div class="fh-form-grid" style="justify-items: normal">
      <div style="display: flex; flex-direction: column; gap: 10px;" formGroupName="days">
        <label class="fw-semibold">Giorni lavorativi</label>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="mon">Lunedì</label>
          <input type="checkbox" id="mon" formControlName="mon">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="tue">Martedì</label>
          <input type="checkbox" id="tue" formControlName="tue">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="wed">Mercoledì</label>
          <input type="checkbox" id="wed" formControlName="wed">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="thu">Giovedì</label>
          <input type="checkbox" id="thu" formControlName="thu">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="fri">Venerdì</label>
          <input type="checkbox" id="fri" formControlName="fri">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="sat">Sabato</label>
          <input type="checkbox" id="sat" formControlName="sat">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="sun">Domenica</label>
          <input type="checkbox" id="sun" formControlName="sun">
        </div>
      </div>

      <div style="display: flex; flex-direction: column; gap: 10px;" formGroupName="hours">
        <label class="fw-semibold">Fascia oraria</label>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="mor">Mattino</label>
          <input type="checkbox" id="mor" formControlName="mor">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="aft">Pomeriggio</label>
          <input type="checkbox" id="aft" formControlName="aft">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="eve">Sera</label>
          <input type="checkbox" id="eve" formControlName="eve">
        </div>
      </div>
    </div>
  </fieldset>
</div>
