<div class="position-relative" [formGroup]="form">
  <input type="text" id="service" class="form-control" formControlName="serviceControl" [ngbTypeahead]="serviceSearch"
    [resultTemplate]="resultRow" [inputFormatter]="formatter" (selectItem)="onFHServiceSelected($event)"
    autocomplete="hjl">
  <i class="bi bi-x-lg position-absolute pointer" style="right: 12px; top: 9px; color: black;" (click)="resetSelection()"></i>

  @if(!isRequestList){
    <div *ngIf="form.get('serviceControl')?.touched && form.get('serviceControl')?.errors || formInvalid">
      <span class="form-error">{{validatorErrorMessage('serviceControl')}}</span>
    </div>
  }

</div>

<ng-template #resultRow let-r="result" let-t="term">
  <div style="max-width: 300px;text-wrap: wrap;">
    <span class="me-2">{{r.customerType}} - {{r.businessLine}} - {{r.name}}:</span>
    <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
  </div>
</ng-template>
