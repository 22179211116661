<div class="fh-form-section" [formGroup]="form">
  <fieldset>
    <legend>Caratteristiche animali</legend>
    <div class="fh-form-grid">
      <div class="w-100" style="grid-template-columns: unset; grid-column: 1/2; margin-top: 20px;"
        formGroupName="catHealing">
        <fieldset style="width: 100%;">
          <legend>Posso curare (Gatti)</legend>
          <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
            <label class="label" for="cat">Gatti</label>
            <input type="checkbox" id="cat" formControlName="cat">
          </div>
        </fieldset>
      </div>
      <div class="w-100" style="grid-template-columns: unset; grid-column: 2/2; margin-top: 20px;"
        formGroupName="catHosting">
        <fieldset style="width: 100%;">
          <legend>Posso ospitare (Gatti)</legend>
          <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
            <label class="label" for="catHosting.cat">Gatti</label>
            <input type="checkbox" id="catHosting.cat" formControlName="cat">
          </div>
        </fieldset>
      </div>
      <div class="w-100" style="grid-template-columns: unset; grid-column: 1/2; margin-top: 20px;"
        formGroupName="dogHealing">
        <fieldset style="width: 100%;">
          <legend>Posso curare (Cani)</legend>
          <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
            <label class="label" for="dog">0-7 kg</label>
            <input type="checkbox" id="dog" formControlName="dog">
            <label class="label" for="dog1">7-15 kg</label>
            <input type="checkbox" id="dog1" formControlName="dog1">
            <label class="label" for="dog2">15-30 kg</label>
            <input type="checkbox" id="dog2" formControlName="dog2">
            <label class="label" for="dog3">30-45 kg</label>
            <input type="checkbox" id="dog3" formControlName="dog3">
            <label class="label" for="dog4">45+ kg</label>
            <input type="checkbox" id="dog4" formControlName="dog4">
          </div>
        </fieldset>
      </div>
      <div class="w-100" style="grid-template-columns: unset; grid-column: 2/2; margin-top: 20px;"
        formGroupName="dogHosting">
        <fieldset style="width: 100%;">
          <legend>Posso ospitare (Cani)</legend>
          <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
            <label class="label" for="dogHosting.dog">0-7 kg</label>
            <input type="checkbox" id="dogHosting.dog" formControlName="dog">
            <label class="label" for="dogHosting.dog1">7-15 kg</label>
            <input type="checkbox" id="dogHosting.dog1" formControlName="dog1">
            <label class="label" for="dogHosting.dog2">15-30 kg</label>
            <input type="checkbox" id="dogHosting.dog2" formControlName="dog2">
            <label class="label" for="dogHosting.dog3">30-45 kg</label>
            <input type="checkbox" id="dogHosting.dog3" formControlName="dog3">
            <label class="label" for="dogHosting.dog4">45+ kg</label>
            <input type="checkbox" id="dogHosting.dog4" formControlName="dog4">
          </div>
        </fieldset>
      </div>
    </div>
  </fieldset>
  <fieldset>
    <legend>Caratteristiche petsitter richiesta</legend>
    <div class="fh-form-grid" style="justify-items: normal">
      <div style="display: flex; flex-direction: column; gap: 10px;" formGroupName="requestedAge">
        <label class="fw-semibold">Età petsitter</label>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="reqage1">18-35 anni</label>
          <input type="checkbox" id="reqage1" formControlName="requestedAgeCluster1">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="reqage2">36-50 anni</label>
          <input type="checkbox" id="reqage2" formControlName="requestedAgeCluster2">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="reqage3">51+ anni</label>
          <input type="checkbox" id="reqage3" formControlName="requestedAgeCluster3">
        </div>
      </div>
      <div style="display: flex; flex-direction: column; gap: 10px;" formGroupName="requestedExp">
        <label class="fw-semibold">Esperienza petsitter richiesta</label>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="reqexp1">Cuccioli</label>
          <input type="checkbox" id="reqexp1" formControlName="requestedExpCluster1">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="reqexp2">Adulti</label>
          <input type="checkbox" id="reqexp2" formControlName="requestedExpCluster2">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="reqexp3">Anziani</label>
          <input type="checkbox" id="reqexp3" formControlName="requestedExpCluster3">
        </div>
      </div>
      <div style="display: flex; flex-direction: column; gap: 10px;">
        <label class="fw-semibold">Disponibilità Petsitter</label>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="hosting">Pet sitting a casa del sitter</label>
          <input type="checkbox" id="hosting" formControlName="hosting">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="grooming">Toelettatura</label>
          <input type="checkbox" id="grooming" formControlName="grooming">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="pension">Pensione</label>
          <input type="checkbox" id="pension" formControlName="pension">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="veterinarian">Accompagnamento a visite veterinarie</label>
          <input type="checkbox" id="veterinarian" formControlName="veterinarian">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="license">Patente</label>
          <input type="checkbox" id="license" formControlName="license">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="coliving">Disp. Convivenza</label>
          <input type="checkbox" id="coliving" formControlName="coliving">
        </div>
      </div>
      <div style="display: flex; flex-direction: column; gap: 10px;">
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;margin-top: 32px;">
          <label class="label" for="homeService">Pet sitting a domicilio</label>
          <input type="checkbox" id="homeService" formControlName="homeService">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="walking">Passeggiata cani</label>
          <input type="checkbox" id="walking" formControlName="walking">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="nightShift">Soggiorno notturno</label>
          <input type="checkbox" id="nightShift" formControlName="nightShift">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="cleaning">Pulizia lettiera</label>
          <input type="checkbox" id="cleaning" formControlName="cleaning">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="car">Automunita</label>
          <input type="checkbox" id="car" formControlName="car">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="relocation">Disp. Trasferte</label>
          <input type="checkbox" id="relocation" formControlName="relocation">
        </div>
      </div>
      <div style="display: flex; flex-direction: column; gap: 10px;">
        <label class="fw-semibold">Formazione Petsitter</label>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="drugs">Somministrazione farmaci</label>
          <input type="checkbox" id="drugs" formControlName="drugs">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="disability">Esperienza con animali con disabilità/psichiche</label>
          <input type="checkbox" id="disability" formControlName="disability">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="mentalIllness">Esperienza con animali con problemi comportamentali</label>
          <input type="checkbox" id="mentalIllness" formControlName="mentalIllness">
        </div>
      </div>
    </div>
  </fieldset>
  <fieldset>
    <legend>Caratteristiche incarico</legend>
    <div class="fh-form-grid">
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="iniziostimato">Inizio stimato</label>
        <input type="date" id="iniziostimato" formControlName="starting">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="durataimpegno">I genitori avrebbero bisogno fino a</label>
        <input type="date" id="durataimpegno" formControlName="estimatedLasting">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="serviceStart">Da quando</label>
        <input type="text" id="serviceStart" formControlName="serviceStart" readonly>
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="serviceDuration">Per quanto tempo</label>
        <input type="text" id="serviceDuration" formControlName="serviceDuration" readonly>
      </div>
      <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
        <label class="fw-semibold" for="continuousJob">Lavoro continuativo</label>
        <input type="checkbox" id="continuousJob" formControlName="continuousJob">
      </div>
      <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
        <label class="fw-semibold" for="moreThanOne">Numero di animali maggiore di 1</label>
        <input type="checkbox" id="moreThanOne" formControlName="moreThanOne">
      </div>
      <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
        <label class="fw-semibold" for="badWorkHours">Orari scomodi - <span style="font-size: 13px; color: grey;">A
            chiamata,
            Periodo breve inferiore al mese, tra le 20 e le 7.30; solo sabato o Domenica; meno o uguale 3 ore/ al
            giorno</span></label>
        <input type="checkbox" id="badWorkHours" formControlName="badWorkHours">
      </div>
      <div class="fh-form-input-field" style="grid-column: span 2;">
        <div style="grid-column: span 2;">
          <label class="fw-semibold" style="display: block;" for="descrizioneimpegno">Descrizione impegno - <span
            style="font-size: 13px; color: grey;">"Serve una petsitter per..."</span></label>
        <textarea rows="6" type="text" id="descrizioneimpegno" maxlength="2048"
          formControlName="requestDescription" style="width: 100%;"></textarea>
        </div>
      </div>
    </div>
  </fieldset>
  @if (inputData !== undefined) {
    <app-request-pricing [province]="provincia" [badWorkHoursInput]="form.get('badWorkHours')!.value" [handicapInput]="false" [moreThanOneInput]="form.get('moreThanOne')!.value"
    [request]="inputData" [reqService]="reqService" (pricing)="updatePricing($event)"></app-request-pricing>
  }
  <fieldset>
    <legend>Orari di lavoro</legend>
    <div class="fh-form-grid" style="justify-items: normal">
      <div style="display: flex; flex-direction: column; gap: 10px;" formGroupName="days">
        <label class="fw-semibold">Giorni lavorativi</label>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="mon">Lunedì</label>
          <input type="checkbox" id="mon" formControlName="mon">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="tue">Martedì</label>
          <input type="checkbox" id="tue" formControlName="tue">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="wed">Mercoledì</label>
          <input type="checkbox" id="wed" formControlName="wed">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="thu">Giovedì</label>
          <input type="checkbox" id="thu" formControlName="thu">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="fri">Venerdì</label>
          <input type="checkbox" id="fri" formControlName="fri">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="sat">Sabato</label>
          <input type="checkbox" id="sat" formControlName="sat">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="sun">Domenica</label>
          <input type="checkbox" id="sun" formControlName="sun">
        </div>
      </div>

      <div style="display: flex; flex-direction: column; gap: 10px;" formGroupName="hours">
        <label class="fw-semibold">Fascia oraria</label>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="mor">Mattino</label>
          <input type="checkbox" id="mor" formControlName="mor">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="aft">Pomeriggio</label>
          <input type="checkbox" id="aft" formControlName="aft">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="eve">Sera</label>
          <input type="checkbox" id="eve" formControlName="eve">
        </div>
      </div>
    </div>
  </fieldset>
</div>
