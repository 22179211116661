import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Page } from "npx-family-happy-common";
import { BehaviorSubject, Subject, map, tap } from "rxjs";
import { CaregiverEngagementFilters } from "src/app/shared/components/caregiver-engagement/caregiver-engagement-models/caregiver-engagement-filters.interface";
import { CaregiverSubscriptionInterface } from "src/app/sellers/caregiver-subscription/caregiver-subscription.interface";
import { CaregiverSelectionEngagementRecord } from "src/app/shared/models/caregiver-selection-engagement-record.model";
import { CaregiverStats } from "src/app/shared/models/caregiver-stats.model";
import { CaregiverSelectionRecord } from "src/app/shared/models/caregiverSelectionRecord.model";
import { environment } from "src/environments/environment";
import { IDEngagementChecked } from "src/app/shared/models/caregiverSelectionRecordSortable.models";

export const DEFAULT_ENGAGED_FILTERS:CaregiverEngagementFilters = {
  phase:''
};

@Injectable({
  providedIn: 'root'
})

export class CaregiverSelectionService implements OnDestroy {

  filtersBB = new BehaviorSubject<any>(null);
  filtersPET = new BehaviorSubject<any>(null);
  filtersNURSE = new BehaviorSubject<any>(null);
  filtersCOLF = new BehaviorSubject<any>(null);

  filtersBBReq = new BehaviorSubject<any>(null);
  filtersPETReq = new BehaviorSubject<any>(null);
  filtersNURSEReq = new BehaviorSubject<any>(null);
  filtersCOLFReq = new BehaviorSubject<any>(null);

  private filterMap: { [key:string]: BehaviorSubject<any>} = {
    BABYSITTER: this.filtersBB,
    PETSITTER: this.filtersPET,
    NURSE: this.filtersNURSE,
    COLF: this.filtersCOLF
  }

  private requestFilterMap: { [key:string]: BehaviorSubject<any>} = {
    BABYSITTER: this.filtersBBReq,
    PETSITTER: this.filtersPETReq,
    NURSE: this.filtersNURSEReq,
    COLF: this.filtersCOLFReq
  }

  filterOldReq = new BehaviorSubject<boolean>(false)

  private baseURL = environment.caregiverSelectionAPI;
  private caregiverRecordsObservable = new Subject<CaregiverSelectionRecord[]>();

  constructor(private http: HttpClient) { }

  ngOnDestroy(): void {
    // this.resetFilters()
  }

  getCaregiverByRequestID(id: number, latitude?: number, longitude?: number, removeOld = false) {
    const params = [
      `id=${id}`
    ]
    if (latitude && longitude) {
      params.push(`latitude=${latitude}`, `longitude=${longitude}`)
    }
    if (removeOld) {
      params.push(`removeOld=${removeOld}`);
    }
    return this.http.get<CaregiverSelectionRecord[]>(`${this.baseURL}/?${params.join('&')}`).pipe(tap((records) => this.caregiverRecordsObservable.next(records)));
  }

  addCaregiverToFavorite(id:number, isFavorite:boolean){
    return this.http.patch<CaregiverSelectionRecord>(`${this.baseURL}/${id}/favorite?isFavorite=${isFavorite}`, '')
  }

  getAllCaregiverNotSubscribed(businessLine:string, pageSize:number, page:number){
    let params = ''
    if(businessLine !== ''){
      params = [
        `businessLine=${businessLine}`,
        `pageNumber=${page}`,
        `pageSize=${pageSize}`
      ].join('&');
    } else {
      params = [
        `pageNumber=${page}`,
        `pageSize=${pageSize}`
      ].join('&');
    }
    return this.http.get<Page<CaregiverSubscriptionInterface>>(`${this.baseURL}/caregivers-not-subscribed?${params}`);
  }

  getCaregiverStats(docRef: string) {
    return this.http.get<CaregiverStats>(`${this.baseURL}/${docRef}/stats`)
      .pipe(map((stats) => ({
        ...stats,
        latestApplication: stats.latestApplication ? new Date(stats.latestApplication) : null,
        latestChosen: stats.latestChosen ? new Date(stats.latestChosen) : null
      })));
  }

  updateCaregiverSelectionCategory(record: CaregiverSelectionRecord, type: string, discardedReason:string) {
    let newRecord = {}
    if(type === "DISCARDED"){
      newRecord = {
        id: record.id,
        date: new Date(),
        status: true,
        type: type,
        discardedReason: discardedReason
      }
    } else {
      newRecord = {
        id: record.id,
        date: new Date(),
        status: true,
        type: type,
      }
    }
    return this.http.patch<any>(`${this.baseURL}/`, newRecord);
  }

  addBabysitterSelectionSuitable(record: CaregiverSelectionRecord) {
    let newRecord = {
      requestID: record.requestID,
      babysitter: record.babysitter,
      petsitter: null,
      nurse: null
    }
    return this.http.post<any>(`${this.baseURL}/`, newRecord);
  }

  addPetsitterSelectionSuitable(record: CaregiverSelectionRecord) {
    let newRecord = {
      requestID: record.requestID,
      petsitter: record.petsitter,
      babysitter: null,
      nurse: null
    }
    return this.http.post<any>(`${this.baseURL}/`, newRecord);
  }

  addNurseSelectionSuitable(record: CaregiverSelectionRecord) {
    let newRecord = {
      requestID: record.requestID,
      babysitter: null,
      petsitter: null,
      nurse: record.nurse
    }
    return this.http.post<any>(`${this.baseURL}/`, newRecord);
  }

  addColfSelectionSuitable(record: CaregiverSelectionRecord) {
    let newRecord = {
      requestID: record.requestID,
      babysitter: null,
      petsitter: null,
      nurse: null,
      colf: record.colf
    }
    return this.http.post<any>(`${this.baseURL}/`, newRecord);
  }

  setFilters(value: any, businessLine:string, isRequest:boolean) {
    if(!isRequest){
      const filterSub = this.filterMap[businessLine];
      if(filterSub){
        filterSub.next(value)
      }
    } else {
      const filterSub = this.requestFilterMap[businessLine];
      if(filterSub){
        filterSub.next(value)
      }
    }
  }

   setOldReq(value: boolean) {
    this.filterOldReq.next(value)
  }

  getFilters(businessLine:string, isRequest:boolean) {
    if(!isRequest){
      const filterSub = this.filterMap[businessLine]
      if(filterSub){
        return filterSub.asObservable()
      } else {
        console.error(`Filtro non trovato per businessLine: ${businessLine}`);
        return null
      }
    } else {
      const filterSub = this.requestFilterMap[businessLine]
      if(filterSub){
        return filterSub.asObservable()
      } else {
        console.error(`Filtro non trovato per businessLine: ${businessLine}`);
        return null
      }
    }
  }

    getOldReq() {
    return this.filterOldReq.asObservable()
  }

  resetFilters(businessLine:string, isRequest:boolean) {
    if(!isRequest){
      const filterSub = this.filterMap[businessLine]
      if(filterSub){
        filterSub.next(null)
      }else {
        console.error(`Filtro non trovato per businessLine: ${businessLine}`);
      }
    } else {
      const filterSub = this.requestFilterMap[businessLine]
      if(filterSub){
        filterSub.next(null)
      }else {
        console.error(`Filtro non trovato per businessLine: ${businessLine}`);
      }
    }
  }

  getStoredCaregiverRecords() {
    return this.caregiverRecordsObservable.asObservable();
  }

  checkCaregiverEngagement(id:string){
    return this.http.get<any>(`${this.baseURL}/caregiver-recent-engagement-check?docRef=${id}`)
  }

  checkCaregiverEngagementIds(ids:string[], requestID:number){
    let body = ids
    return this.http.post<IDEngagementChecked[]>(`${this.baseURL}/engagement-status/${requestID}`, body)
  }

  getEngagedRequestsByDocRef(page: number, pageSize: number, docRef:String, filters = DEFAULT_ENGAGED_FILTERS) {
    const params = [
      `pageNumber=${page}`,
      `pageSize=${pageSize}`,
      `docRef=${docRef}`,
      `${this.convertFilterObjectToQueryParam(filters)}`,
    ].join('&');

    return this.http.get<Page<CaregiverSelectionEngagementRecord>>(`${this.baseURL}/requests?${params}`);
  }

  convertFilterObjectToQueryParam(filters: any) {
    let queryString = '';
    Object.entries(filters).forEach((entry: [string, any | null]) => {
      if (entry[1] !== null && entry[1] !== '') {
        queryString += `${queryString === '' ? '' : '&'}${entry[0]}=${encodeURIComponent(entry[1])}`;
      }
    });
    return queryString;
  }
}
