<div id="modal" class="modal-container" (click)="dismiss($event)">
  <div class="modal-content">
    <form [formGroup]="filters">
      <div class="d-flex flex-column gap-2">
        <p class="category">Disponibilità</p>
        <div class="checkbox-list">
          <div>
            <input type="checkbox" id="homework" formControlName="homework">
            <label for="homework">Aiuto compiti</label>
          </div>
          <div>
            <input type="checkbox" id="cleaning" formControlName="cleaning">
            <label for="cleaning">Aiuto pulizie</label>
          </div>
          <div>
            <input type="checkbox" id="cooking" formControlName="cooking">
            <label for="cooking">Cucinare per il bambino</label>
          </div>
          <div>
            <input type="checkbox" id="taxi" formControlName="taxi">
            <label for="taxi">Taxisitter</label>
          </div>
          <div>
            <input type="checkbox" id="licence" formControlName="licence">
            <label for="licence">Patente</label>
          </div>
          <div>
            <input type="checkbox" id="car" formControlName="car">
            <label for="car">Automunita</label>
          </div>
          <div>
            <input type="checkbox" id="smoker" formControlName="smoker">
            <label for="smoker">Non Fumatrice</label>
          </div>
          <div>
            <input type="checkbox" id="greenpass" formControlName="greenpass">
            <label for="greenpass">Greenpass</label>
          </div>
          <div>
            <input type="checkbox" id="disostruzione" formControlName="disostruzione">
            <label for="disostruzione">Disostruzione</label>
          </div>
          <div>
            <input type="checkbox" id="fhsub" formControlName="fhsub">
            <label for="fhsub">Iscrizione a Family+Happy</label>
          </div>
          <div>
            <input type="checkbox" id="ref" formControlName="ref">
            <label for="ref">Almeno una referenza</label>
          </div>
          <div>
            <input type="checkbox" id="rating" formControlName="rating">
            <label for="rating">Voto generale maggiore o uguale a 6</label>
          </div>
          <div>
            <input type="checkbox" id="ignored" formControlName="ignored">
            <label for="ignored">Non scelta per 3 o più volte</label>
          </div>
          <div>
            <input type="checkbox" id="fullCV" formControlName="fullCV">
            <label for="fullCV">CV Completo</label>
          </div>
        </div>
        <div *ngIf="showAgeFilter">
          <p class="category">Età</p>
          <div style="display: flex; gap: 15px;">
            <input type="number" min="1" max="99" formControlName="minAge" placeholder="Da...">
            <input type="number" min="1" max="99" formControlName="maxAge" placeholder="A...">
          </div>
        </div>
        <div>
          <p class="category">Distanza massima in KM</p>
          <input type="number" id="distance" formControlName="distance" placeholder="fino a...">
        </div>
      </div>
      <div style="display: flex;flex-direction:column;gap:15px">
        <div formGroupName="days">
          <p class="category">Giorni disponibili</p>
          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="sun" formControlName="sun">
              <label for="sun">Domenica</label>
            </div>
            <div>
              <input type="checkbox" id="mon" formControlName="mon">
              <label for="mon">Lunedì</label>
            </div>
            <div>
              <input type="checkbox" id="tue" formControlName="tue">
              <label for="tue">Martedì</label>
            </div>
            <div>
              <input type="checkbox" id="wed" formControlName="wed">
              <label for="wed">Mercoledì</label>
            </div>
            <div>
              <input type="checkbox" id="thu" formControlName="thu">
              <label for="thu">Giovedì</label>
            </div>
            <div>
              <input type="checkbox" id="fri" formControlName="fri">
              <label for="fri">Venerdì</label>
            </div>
            <div>
              <input type="checkbox" id="sat" formControlName="sat">
              <label for="sat">Sabato</label>
            </div>
          </div>
        </div>
        <div formGroupName="hours">
          <p class="category">Orari di disponibilità</p>
          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="mor" formControlName="mor">
              <label for="mor">Mattino</label>
            </div>
            <div>
              <input type="checkbox" id="aft" formControlName="aft">
              <label for="aft">Pomeriggio</label>
            </div>
            <div>
              <input type="checkbox" id="eve" formControlName="eve">
              <label for="eve">Sera</label>
            </div>
          </div>
        </div>
        <div formGroupName="contract">
          <p class="category">Interessi per incarichi</p>
          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="occ" formControlName="occ">
              <label for="occ">Occasionali</label>
            </div>
            <div>
              <input type="checkbox" id="part" formControlName="part">
              <label for="part">Part-time</label>
            </div>
            <div>
              <input type="checkbox" id="full" formControlName="full">
              <label for="full">Full-time</label>
            </div>
          </div>
        </div>
      </div>

      <div style="display: flex;flex-direction:column;gap:15px">
        <div formGroupName="lang">
          <p class="category">Lingue</p>
          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="english" formControlName="english">
              <label for="english">Inglese</label>
            </div>
            <div>
              <input type="checkbox" id="french" formControlName="french">
              <label for="french">Francese</label>
            </div>
            <div>
              <input type="checkbox" id="german" formControlName="german">
              <label for="german">Tedesco</label>
            </div>
            <div>
              <input type="checkbox" id="italian" formControlName="italian">
              <label for="italian">Italiano</label>
            </div>
            <div>
              <input type="checkbox" id="spanish" formControlName="spanish">
              <label for="spanish">Spagnolo</label>
            </div>
          </div>
          <p class="category pt-3">Madrelingua</p>
          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="englishAdvanced" formControlName="englishAdvanced">
              <label for="englishAdvanced">Inglese madrelingua</label>
            </div>
            <div>
              <input type="checkbox" id="frenchAdvanced" formControlName="frenchAdvanced">
              <label for="frenchAdvanced">Francese madrelingua</label>
            </div>
            <div>
              <input type="checkbox" id="germanAdvanced" formControlName="germanAdvanced">
              <label for="germanAdvanced">Tedesco madrelingua</label>
            </div>
            <div>
              <input type="checkbox" id="italianAdvanced" formControlName="italianAdvanced">
              <label for="italianAdvanced">Italiano madrelingua</label>
            </div>
            <div>
              <input type="checkbox" id="spanishAdvanced" formControlName="spanishAdvanced">
              <label for="spanishAdvanced">Spagnolo madrelingua</label>
            </div>
          </div>
        </div>
        <div formGroupName="exp">
          <p class="category">Esperienza</p>
          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="cluster1" formControlName="cluster1">
              <label for="cluster1">Esperienza 0-6 mesi</label>
            </div>
            <div>
              <input type="checkbox" id="cluster2" formControlName="cluster2">
              <label for="cluster2">Esperienza 7-24 mesi</label>
            </div>
            <div>
              <input type="checkbox" id="cluster3" formControlName="cluster3">
              <label for="cluster3">Esperienza 2-5anni</label>
            </div>
            <div>
              <input type="checkbox" id="cluster4" formControlName="cluster4">
              <label for="cluster4">Esperienza >5 anni</label>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div style="display: grid;grid-template-columns: repeat(3, 1fr);">
      <p class="my-button secondary-button" (click)="resetFilters()">Reset</p>
      <p class="my-button" (click)="emitFilter()">Filtra</p>
    </div>
  </div>
</div>
