<div class="accordion" [formGroup]="form" id="collapseExample">
  <div class="accordion-item">
    <h2 class="accordion-header " id="heading1c">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1c"
        aria-expanded="false" aria-controls="collapse1c">
        Pricing Caregiver
      </button>
    </h2>
    <div id="collapse1c" class="accordion-collapse collapse" role="region" aria-labelledby="heading1c">
      <div class="accordion-body">
        <app-pricing-caregiver-switch [currentMode]="caregiverPricingMode" class="pt-3"
          (modeChanged)="switchCaregiverMode($event)"></app-pricing-caregiver-switch>
        @if(caregiverPricingMode === 'netta'){
        <div class="fh-form-grid pt-5">

          <div style="display: block; width: 100%; text-align: left;">
            <div style="display: flex;">
              <label class="fw-semibold pb-3 pe-2" for="netHourCost" style="display: block; text-align: left;">
                Tariffa oraria netta caregiver
              </label>
              <img src="/assets/refresh.png" style="cursor: pointer;" width="24" height="24"
                (click)="updateNetHourPrice()">
            </div>
            <div style="display: flex; align-items: center; gap: 5px;">
              <div class="net-hour-price-container" style="display: flex; align-items: center; gap: 5px; width: 100%;">
                <i class="bi bi-dash" (click)="decreaseNetHourCostByOne()"></i>
                <input type="number" min="0" id="netHourCost" formControlName="netHourCost">
                <i class="bi bi-plus" (click)="increaseNetHourCostByOne()"></i>
              </div>
            </div>
          </div>

          <div class="fh-form-input-field"
            style="display: flex; flex-direction: column; align-items: flex-start; width: 100%;">
            <!-- Contenitore per "Rit. Acconto da aggiungere" -->
            <label class="" style="display: block; text-align: left; font-size: 18px;">
              ALTRE OPZIONI:
            </label>
            <div style="display: grid; grid-template-columns: 1fr 20px; align-items: baseline; width: 100%;">
              <label class="fw-semibold pe-1" for="withholdingTax">Rit. Acconto da aggiungere</label>
              <input id="withholdingTax" type="checkbox" formControlName="withholdingTax">
            </div>
            <div style="display: grid; grid-template-columns: 1fr 20px; align-items: baseline; width: 100%;">
              <label class="fw-semibold pe-1" for="taxisitter">Rimborso spese benzina</label>
              <input id="taxisitter" type="checkbox" formControlName="taxisitter">
            </div>
          </div>
        </div>
        <div class="pt-4">
          <h3 class="pb-2">Riepilogo</h3>
          <span class="text-uppercase fw-bold">TARIFFA NETTA CAREGIVER:</span>
          <p class="pt-1">{{recapMessageCaregiver}}</p>
        </div>
        } @else {
        <div class="fh-form-grid pt-5">

          <div style="display: block; width: 100%; text-align: left;">
            <label class="fw-semibold pb-1" for="flatCost">Tariffa Forfettaria</label>
            <div style="display: flex; align-items: center; gap: 5px;">
              <div class="net-hour-price-container">
                <i class="bi bi-dash" (click)="decreaseFlatCostByOne()"></i>
                <input type="number" min=0 id="flatCost" formControlName="flatCost">
                <i class="bi bi-plus" (click)="increaseFlatCostByOne()"></i>
              </div>
            </div>
          </div>

          <div style="display: block; width: 100%; text-align: left; align-self: baseline;">
            <div>
              <label class="fw-semibold pb-1" for="flatCostTiming">Periodicità Tariffa Forfettaria</label>
            </div>
            <div>
              <select id="flatCostTiming" formControlName="flatCostTiming">
                <option [value]="0" default>Mese</option>
                <option [value]="1">Settimana</option>
                <!-- <option [value]="2">Ora</option> -->
                <option [value]="3">Una tantum</option>
                <option [value]="4">Giorno</option>
                <option [value]="5">Notte</option>
                <option [value]="6">Passaggio</option>
              </select>
            </div>
          </div>
          <div class="fh-form-input-field pt-3"
            style="display: flex; flex-direction: column; align-items: flex-start; width: 100%;">
            <!-- Contenitore per "Rit. Acconto da aggiungere" -->
            <label class="fw-semibold" style="display: block; text-align: left;">
              ALTRE OPZIONI:
            </label>
            <div style="display: grid; grid-template-columns: 1fr 20px; align-items: baseline; width: 100%;">
              <label class="fw-semibold pe-1" for="withholdingTax">Rit. Acconto da aggiungere</label>
              <input id="withholdingTax" type="checkbox" formControlName="withholdingTax">
            </div>
            <div style="display: grid; grid-template-columns: 1fr 20px; align-items: baseline; width: 100%;">
              <label class="fw-semibold pe-1" for="taxisitter">Rimborso spese benzina</label>
              <input id="taxisitter" type="checkbox" formControlName="taxisitter">
            </div>
          </div>
        </div>

        <div class="pt-4">
          <h3 class="pb-2">Riepilogo</h3>
          <span class="text-uppercase fw-bold">TARIFFA FORFETTARIA CAREGIVER</span>
          <p class="pt-1">{{recapMessageCaregiver}}</p>

        </div>
        }
      </div>
    </div>
  </div>



  <div class="accordion-item">
    <h2 class="accordion-header " id="heading2c">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2c"
        aria-expanded="false" aria-controls="collapse2c">
        Pricing Famiglia
      </button>
    </h2>
    <div id="collapse2c" class="accordion-collapse collapse" role="region" aria-labelledby="heading2c">
      <div class="accordion-body">
        <app-pricing-family-switch [currentMode]="familyPricingMode"
          (modeChanged)="switchFamilyMode($event)"></app-pricing-family-switch>
        @if( familyPricingMode === 'servizio'){
        <div class="fh-form-grid pt-5">
        </div>
        <div class="pt-1">
          <h3 class="pb-2">Riepilogo</h3>
          <span class="text-uppercase fw-bold">SERVIZIO: {{reqService.name}}</span>
          <p class="pt-1">
            Il costo totale del pacchetto è: <b>{{servicePrice}}€</b></p>
          <!-- <p class="pt-1"></p> -->
        </div>
        } @else if (familyPricingMode === 'pacchetto ore') {
        <div class="fh-form-grid pt-5">
          <div class="fh-form-input-field">
            <label class="fw-semibold" for="hours-amount">Quantità ore pacchetto</label>
            <input id="hours-amount" type="number" formControlName="hoursAmount">
          </div>
        </div>
        <div class="pt-4">
          <h3 class="pb-2">Riepilogo</h3>
          <span class="text-uppercase fw-bold">PACCHETTO ORE: {{reqService.name}}</span>
          <p class="pt-1">
            Il pacchetto comprende {{form.get('hoursAmount')?.value}} ore ed ha un costo di {{costoPacchettoOre ??
            '0,00'}} €</p>
        </div>
        } @else {
        <div class="fh-form-grid pt-5">
          <div class="fh-form-input-field">
            <label class="fw-semibold" for="hours-amount">Quantità ore pacchetto</label>
            <input id="hours-amount" type="number" formControlName="hoursAmount">
          </div>
          <div class="fh-form-input-field">
            <label class="fw-semibold" for="packageFlatCost">Costo Forfettario Pacchetto</label>
            <input id="packageFlatCost" type="number" formControlName="packageFlatCost" min="0">
          </div>
        </div>
        <div class="pt-4">
          <h3 class="pb-2">Riepilogo</h3>
          <span class="text-uppercase fw-bold">SERVIZIO B2B: {{reqService.name}}</span>
          <p class="pt-1">Il costo del pacchetto è di {{form.get('packageFlatCost')?.value?.toFixed(2)}} €
            e comprende {{form.get('hoursAmount')?.value}} ore di servizio</p>
        </div>
        }
      </div>
    </div>
  </div>
</div>

<app-alert *ngIf="showAlert" [title]="'Attenzione'" [message]="alertMessage" [data]="caregiverPricingMode"
  (save)="confirmSwitch($event)"></app-alert>
