import { Babysitter, Colf, Nurse, Petsitter } from "npx-family-happy-common";

export const getURLRelated = (type: string, docRef: string) => {
  switch (type) {
    case 'REQUEST':
      return `/request-detail/${docRef}`;
    case 'BABYSITTER':
      return `/babysitter-detail/${docRef}`;
    case 'PETSITTER':
      return `/petsitter-detail/${docRef}`;
    case 'NURSE':
      return `/nurse-detail/${docRef}`;
    case 'COLF':
      return `/colf-detail/${docRef}`;
    case 'PAYMENT':
      return `/payments/${docRef}`;
    default:
      return '';
  }
}

export const applyFilter = (bb: Babysitter, filters: any, distance: number) => {
  if (bb.rating.dangerous) {
    return false;
  }
  if (Object.keys(filters).length === 0) {
    return true;
  } else {
    let match = true;
    if (filters.car) {
      match = match && bb.avail.car;
    }
    if (filters.cleaning) {
      match = match && bb.avail.cleaning;
    }
    if (filters.cooking) {
      match = match && bb.avail.cooking;
    }
    if (filters.fhsub) {
      match = match && (bb.info.subscription === 'ABBONATO' || bb.info.manualSubscription === 'ABBONATO');
    }
    if (filters.homework) {
      match = match && bb.avail.homework;
    }
    if (filters.licence) {
      match = match && bb.avail.licence;
    }
    if (filters.smoker) {
      match = match && !bb.avail.smoker;
    }
    if (filters.taxi) {
      match = match && bb.avail.taxi;
    }
    if (filters.exp.cluster1) {
      match = match && bb.exp.cluster1.cluster1Age > 0;
    }
    if (filters.exp.cluster2) {
      match = match && bb.exp.cluster2.cluster2Age > 0;
    }
    if (filters.exp.cluster3) {
      match = match && bb.exp.cluster3.cluster3Age > 0;
    }
    if (filters.exp.cluster4) {
      match = match && bb.exp.cluster4.cluster4Age > 0;
    }
    if (filters.lang.english) {
      match = match && (bb.lang.english === 'Ottimo');
    }
    if (filters.lang.englishAdvanced) {
      match = match && (bb.lang.english === 'Madrelingua');
    }
    if (filters.lang.french) {
      match = match && (bb.lang.french === 'Ottimo');
    }
    if (filters.lang.frenchAdvanced) {
      match = match && (bb.lang.french === 'Madrelingua');
    }
    if (filters.lang.german) {
      match = match && (bb.lang.german === 'Ottimo');
    }
    if (filters.lang.germanAdvanced) {
      match = match && (bb.lang.german === 'Madrelingua');
    }
    if (filters.lang.italian) {
      match = match && (bb.lang.italian === 'Ottimo');
    }
    if (filters.lang.italianAdvanced) {
      match = match && (bb.lang.italian === 'Madrelingua');
    }
    if (filters.lang.spanish) {
      match = match && (bb.lang.spanish === 'Ottimo');
    }
    if (filters.lang.spanishAdvanced) {
      match = match && (bb.lang.spanish === 'Madrelingua');
    }
    if (filters.disostruzione) {
      match = match && (bb.certificates.disostruzione || bb.certificates.disostruzioneCertURL) ? true : false;
    }
    if (filters.fullCV) {
      match = match && (bb.info.description ? true : false);
    }
    if (filters.minAge) {
      const minAge = filters.minAge;
      if (filters.maxAge) {
        const maxAge = filters.maxAge;
        match = match && (bb.info.age >= minAge && bb.info.age <= maxAge);
      } else {
        match = match && (bb.info.age >= minAge);
      }
    }

    if (filters.maxAge) {
      const maxAge = filters.maxAge;
      if (filters.minAge) {
        const minAge = filters.minAge;
        match = match && (bb.info.age >= minAge && bb.info.age <= maxAge);
      } else {
        match = match && (bb.info.age <= maxAge);
      }
    }
    const days = fromAvailObjectToString(filters.days, 'days');
    if (days !== '') {
      match = match && matchAvailString(days, bb.avail.days);
    }
    const contract = fromAvailObjectToString(filters.contract, 'contract');
    if (contract !== '') {
      match = match && matchAvailString(contract, bb.avail.contract);
    }
    const hours = fromAvailObjectToString(filters.hours, 'hours');
    if (hours !== '') {
      match = match && matchAvailString(hours, bb.avail.hours);
    }
    if (filters.ignored) {
      match = match && bb.engagement.ignored < 3;
    }
    if (filters.rating) {
      match = match && bb.rating.overall >= 6;
    }
    if (filters.ref) {
      match = match && bb.reference.f_surname !== '';
    }
    if (filters.distance) {
      match = match && distance <= filters.distance
    }
    return match;
  }
}
export const applyPetsitterFilter = (petsitter: Petsitter, filters: any, distance: number) => {

  if (petsitter.rating.dangerous) {
    return false;
  }
  if (Object.keys(filters).length === 0) {
    return true;
  } else {
    let match = true;
    if (filters.catHealing.cat === true) {
      match = match && petsitter.avail.catHealing.includes('cat')
    }
    if (filters.catHosting.cat === true) {
      match = match && petsitter.avail.catHosting.includes('cat')
    }

    if (filters.dogHealing.dog === true) {
      match = match && petsitter.avail.dogHealing.includes('dog')
    }
    if (filters.dogHosting.dog === true) {
      match = match && petsitter.avail.dogHosting.includes('dog')
    }

    if (filters.dogHealing.dog1 === true) {
      match = match && petsitter.avail.dogHealing.includes('dog1')
    }
    if (filters.dogHosting.dog1 === true) {
      match = match && petsitter.avail.dogHosting.includes('dog1')
    }

    if (filters.dogHealing.dog2 === true) {
      match = match && petsitter.avail.dogHealing.includes('dog2')
    }
    if (filters.dogHosting.dog2 === true) {
      match = match && petsitter.avail.dogHosting.includes('dog2')
    }

    if (filters.dogHealing.dog3 === true) {
      match = match && petsitter.avail.dogHealing.includes('dog3')
    }
    if (filters.dogHosting.dog3 === true) {
      match = match && petsitter.avail.dogHosting.includes('dog3')
    }

    if (filters.dogHealing.dog4 === true) {
      match = match && petsitter.avail.dogHealing.includes('dog4')
    }
    if (filters.dogHosting.dog4 === true) {
      match = match && petsitter.avail.dogHosting.includes('dog4')
    }

    if (filters.disability) {
      match = match && petsitter.lang.disability;
    }
    if (filters.drugs) {
      match = match && petsitter.lang.drugs;
    }
    if (filters.illness) {
      match = match && petsitter.lang.mentalIllness;
    }
    if (filters.homeService) {
      match = match && petsitter.avail.homeService;
    }
    if (filters.hosting) {
      match = match && petsitter.avail.hosting;
    }
    if (filters.nightShift) {
      match = match && petsitter.avail.nightShift;
    }
    if (filters.pension) {
      match = match && petsitter.avail.pension;
    }
    if (filters.walking) {
      match = match && petsitter.avail.walking;
    }
    if (filters.fhsub) {
      match = match && (petsitter.info.subscription === 'ABBONATO' || petsitter.info.manualSubscription === 'ABBONATO');
    }
    if (filters.exp.cluster1) {
      match = match && petsitter.exp.cluster1.cluster1Age > 0;
    }
    if (filters.exp.cluster2) {
      match = match && petsitter.exp.cluster2.cluster2Age > 0;
    }
    if (filters.exp.cluster3) {
      match = match && petsitter.exp.cluster3.cluster3Age > 0;
    }
    if (filters.fullCV) {
      match = match && (petsitter.info.description ? true : false);
    }
    if (filters.evaluation) {
      match = match && (petsitter.rating.overall > 6);
    }
    if (filters.minAge) {
      const minAge = filters.minAge;
      if (filters.maxAge) {
        const maxAge = filters.maxAge;
        match = match && (petsitter.info.age >= minAge && petsitter.info.age <= maxAge);
      } else {
        match = match && (petsitter.info.age >= minAge);
      }
    }

    if (filters.maxAge) {
      const maxAge = filters.maxAge;
      if (filters.minAge) {
        const minAge = filters.minAge;
        match = match && (petsitter.info.age >= minAge && petsitter.info.age <= maxAge);
      } else {
        match = match && (petsitter.info.age <= maxAge);
      }
    }
    const days = fromAvailObjectToString(filters.days, 'days');
    if (days !== '') {
      match = match && matchAvailString(days, petsitter.avail.days);
    }
    const contract = fromAvailObjectToString(filters.contract, 'contract');
    if (contract !== '') {
      match = match && matchAvailString(contract, petsitter.avail.contract);
    }
    const hours = fromAvailObjectToString(filters.hours, 'hours');
    if (hours !== '') {
      match = match && matchAvailString(hours, petsitter.avail.hours);
    }
    if (filters.distance) {
      match = match && distance <= filters.distance
    }
    return match;
  }
}
export const applyNurseFilter = (nurse: Nurse, filters: any, distance: number) => {

  if (nurse.rating.dangerous) {
    return false;
  }
  if (Object.keys(filters).length === 0) {
    return true;
  } else {
    let match = true;
    if (filters.car) {
      match = match && nurse.avail.car;
    }
    if (filters.lang.english) {
      match = match && (nurse.lang.english === 'Ottimo' || nurse.lang.english === 'Madrelingua');
    }
    if (filters.lang.italian) {
      match = match && (nurse.lang.italian === 'Ottimo' || nurse.lang.italian === 'Madrelingua');
    }
    if (filters.lang.spanish) {
      match = match && (nurse.lang.spanish === 'Ottimo' || nurse.lang.spanish === 'Madrelingua');
    }
    if (filters.license) {
      match = match && nurse.avail.license;
    }
    if (filters.roles.changing) {
      match = match && nurse.avail.changing;
    }
    if (filters.roles.cleaning) {
      match = match && nurse.avail.cleaning;
    }
    if (filters.roles.dayShift) {
      match = match && nurse.avail.dayShift;
    }
    if (filters.roles.hospital) {
      match = match && nurse.avail.hospital;
    }
    if (filters.roles.nightShift) {
      match = match && nurse.avail.nightShift;
    }
    if (filters.roles.taxi) {
      match = match && nurse.avail.taxi;
    }
    if (filters.roles.taxiNoCar) {
      match = match && nurse.avail.taxiNoCar;
    }
    if (filters.roles.walking) {
      match = match && nurse.avail.walking;
    }
    if (filters.specialities.dressings) {
      match = match && nurse.lang.specialities.dressings;
    }
    if (filters.specialities.injections) {
      match = match && nurse.lang.specialities.injections;
    }
    if (filters.specialities.glycemia) {
      match = match && nurse.lang.specialities.glycemia;
    }
    if (filters.specialities.technology) {
      match = match && nurse.lang.specialities.technology;
    }
    if (filters.fhsub) {
      match = match && (nurse.info.subscription === 'ABBONATO' || nurse.info.manualSubscription === 'ABBONATO');
    }
    if (filters.exp.cluster1) {
      match = match && nurse.exp.cluster1.cluster1Age > 0;
    }
    if (filters.exp.cluster2) {
      match = match && nurse.exp.cluster2.cluster2Age > 0;
    }
    if (filters.rating) {
      match = match && nurse.rating.overall >= 6;
    }
    if (filters.minAge) {
      const minAge = filters.minAge;
      if (filters.maxAge) {
        const maxAge = filters.maxAge;
        match = match && (nurse.info.age >= minAge && nurse.info.age <= maxAge);
      } else {
        match = match && (nurse.info.age >= minAge);
      }
    }

    if (filters.maxAge) {
      const maxAge = filters.maxAge;
      if (filters.minAge) {
        const minAge = filters.minAge;
        match = match && (nurse.info.age >= minAge && nurse.info.age <= maxAge);
      } else {
        match = match && (nurse.info.age <= maxAge);
      }
    }
    const days = fromAvailObjectToString(filters.days, 'days');
    if (days !== '') {
      match = match && matchAvailString(days, nurse.avail.days);
    }
    const contract = fromAvailObjectToString(filters.contract, 'contract');
    if (contract !== '') {
      match = match && matchAvailString(contract, nurse.avail.contract);
    }
    const hours = fromAvailObjectToString(filters.hours, 'hours');
    if (hours !== '') {
      match = match && matchAvailString(hours, nurse.avail.hours);
    }
    if (filters.distance) {
      match = match && distance <= filters.distance
    }
    return match;
  }
}
export const applyColfFilter = (colf: Colf, filters: any, distance: number) => {

  if (colf.rating.dangerous) {
    return false;
  }
  if (Object.keys(filters).length === 0) {
    return true;
  } else {
    let match = true;
    if (filters.car) {
      match = match && colf.avail.car;
    }
    if (filters.lang.english) {
      match = match && (colf.lang.english === 'Ottimo' || colf.lang.english === 'Madrelingua');
    }
    if (filters.lang.italian) {
      match = match && (colf.lang.italian === 'Ottimo' || colf.lang.italian === 'Madrelingua');
    }
    if (filters.lang.spanish) {
      match = match && (colf.lang.spanish === 'Ottimo' || colf.lang.spanish === 'Madrelingua');
    }
    if (filters.license) {
      match = match && colf.avail.license;
    }
    if (filters.roles.coliving) {
      match = match && colf.avail.coliving;
    }
    if (filters.roles.cooking) {
      match = match && colf.avail.cooking;
    }
    if (filters.roles.gardening) {
      match = match && colf.avail.gardening;
    }
    if (filters.roles.groceries) {
      match = match && colf.avail.groceries;
    }
    if (filters.roles.helping) {
      match = match && colf.avail.helping;
    }
    if (filters.roles.houseCleaning) {
      match = match && colf.avail.houseCleaning;
    }
    if (filters.roles.ironing) {
      match = match && colf.avail.ironing;
    }
    if (filters.roles.sewing) {
      match = match && colf.avail.sewing;
    }
    if (filters.roles.terraceCleaning) {
      match = match && colf.avail.terraceCleaning;
    }
    if (filters.roles.washingMachines) {
      match = match && colf.avail.washingMachines;
    }
    if (filters.roles.windowCleaning) {
      match = match && colf.avail.windowCleaning;
    }
    // if (filters.specialities.dressings) {
    //   match = match && colf.lang.specialities.dressings;
    // }
    // if (filters.specialities.injections) {
    //   match = match && colf.lang.specialities.injections;
    // }
    // if (filters.specialities.glycemia) {
    //   match = match && nurse.lang.specialities.glycemia;
    // }
    // if (filters.specialities.technology) {
    //   match = match && nurse.lang.specialities.technology;
    // }
    if (filters.fhsub) {
      match = match && (colf.info.subscription === 'ABBONATO' || colf.info.manualSubscription === 'ABBONATO');
    }
    // if (filters.exp.cluster1) {
    //   match = match && nurse.exp.cluster1.cluster1Age > 0;
    // }
    // if (filters.exp.cluster2) {
    //   match = match && nurse.exp.cluster2.cluster2Age > 0;
    // }
    if (filters.rating) {
      match = match && colf.rating.overall >= 6;
    }
    if (filters.minAge) {
      const minAge = filters.minAge;
      if (filters.maxAge) {
        const maxAge = filters.maxAge;
        match = match && (colf.info.age >= minAge && colf.info.age <= maxAge);
      } else {
        match = match && (colf.info.age >= minAge);
      }
    }

    if (filters.maxAge) {
      const maxAge = filters.maxAge;
      if (filters.minAge) {
        const minAge = filters.minAge;
        match = match && (colf.info.age >= minAge && colf.info.age <= maxAge);
      } else {
        match = match && (colf.info.age <= maxAge);
      }
    }
    const days = fromAvailObjectToString(filters.days, 'days');
    if (days !== '') {
      match = match && matchAvailString(days, colf.avail.days);
    }
    const contract = fromAvailObjectToString(filters.contract, 'contract');
    if (contract !== '') {
      match = match && matchAvailString(contract, colf.avail.contract);
    }
    const hours = fromAvailObjectToString(filters.hours, 'hours');
    if (hours !== '') {
      match = match && matchAvailString(hours, colf.avail.hours);
    }
    if (filters.distance) {
      match = match && distance <= filters.distance
    }
    return match;
  }
}
const matchAvailString = (filter: string, value: string) => {
  if (!value) {
    return false;
  }
  let match = true;
  const result = filter.split('-');
  result.forEach(elem => {
    match = match && value.includes(elem);
  })
  return match
}

const fromAvailObjectToString = (object: any, type: string) => {
  let result = '';
  if (object) {
    switch (type) {
      case 'hours':
        result += object['mor'] ? 'mor' : '';
        result += object['aft'] ? (result !== '' ? '-aft' : 'aft') : '';
        result += object['eve'] ? (result !== '' ? '-eve' : 'eve') : '';
        return result;
      case 'days':
        result += object['sun'] ? 'sun' : '';
        result += object['mon'] ? (result !== '' ? '-mon' : 'mon') : '';
        result += object['tue'] ? (result !== '' ? '-tue' : 'tue') : '';
        result += object['wed'] ? (result !== '' ? '-wed' : 'wed') : '';
        result += object['thu'] ? (result !== '' ? '-thu' : 'thu') : '';
        result += object['fri'] ? (result !== '' ? '-fri' : 'fri') : '';
        result += object['sat'] ? (result !== '' ? '-sat' : 'sat') : '';
        return result;
      case 'contract':
        result += object['occ'] ? 'occ' : '';
        result += object['part'] ? (result !== '' ? '-part' : 'part') : '';
        result += object['full'] ? (result !== '' ? '-full' : 'full') : '';
        return result;
      default:
        return '';
    }
  } else {
    return result;
  }
}
