<div id="modal" class="modal-container" (click)="dismiss($event)">
  <div class="modal-content">
    <form [formGroup]="filters">
      <div>
        <div style="display: flex;flex-direction:column;gap:15px">
          <div formGroupName="roles">
            <p class="category">Disponibilità</p>
            <div class="checkbox-list">
              <div>
                <input type="checkbox" id="cooking" formControlName="cooking" name="cooking">
                <label for="cooking">Preparazione Pasti </label>
              </div>
              <div class="d-flex">
                <input type="checkbox" id="gardening" formControlName="gardening" name="gardening">
                <label for="gardening">Giardinaggio</label>
              </div>
              <div>
                <input type="checkbox" id="groceries" formControlName="groceries" name="groceries">
                <label for="groceries">Aiuto con la spesa</label>
              </div>
              <div>
                <input type="checkbox" id="helping" formControlName="helping" name="helping">
                <label for="helping">Aiuto commissioni</label>
              </div>
              <div>
                <input type="checkbox" id="houseCleaning" formControlName="houseCleaning" name="houseCleaning">
                <label for="houseCleaning">Pulizia e lavori domestici</label>
              </div>
              <div>
                <input type="checkbox" id="ironing" formControlName="ironing" name="ironing">
                <label for="ironing">Stiro</label>
              </div>
              <div>
                <input type="checkbox" id="sewing" formControlName="sewing" name="sewing">
                <label for="sewing">Cucito/ Aggiustamento abiti</label>
              </div>
              <div>
                <input type="checkbox" id="terraceCleaning" formControlName="terraceCleaning" name="terraceCleaning">
                <label for="terraceCleaning">Pulizia terrazzi</label>
              </div>
              <div>
                <input type="checkbox" id="washingMachines" formControlName="washingMachines" name="washingMachines">
                <label for="washingMachines">Lavatrice</label>
              </div>
              <div>
                <input type="checkbox" id="windowCleaning" formControlName="windowCleaning" name="windowCleaning">
                <label for="windowCleaning">Pulizie tapparelle/ zanzariere/ vetri</label>
              </div>
            </div>
          </div>
          <div>
            <p class="category">Generici</p>
            <div class="checkbox-list">
              <div>
                <input type="checkbox" id="rating" formControlName="rating">
                <label for="rating">Voto generale maggiore o uguale a 6</label>
              </div>
              <div>
                <input type="checkbox" id="license" formControlName="license">
                <label for="license">Patente</label>
              </div>
              <div>
                <input type="checkbox" id="car" formControlName="car">
                <label for="car">Automunita</label>
              </div>
              <div>
                <input type="checkbox" id="fhsub" formControlName="fhsub">
                <label for="fhsub">Iscrizione a Family+Happy</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex;flex-direction:column;gap:15px">
        <div formGroupName="days">
          <p class="category">Giorni disponibili</p>
          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="sun" formControlName="sun">
              <label for="sun">Domenica</label>
            </div>
            <div>
              <input type="checkbox" id="mon" formControlName="mon">
              <label for="mon">Lunedì</label>
            </div>
            <div>
              <input type="checkbox" id="tue" formControlName="tue">
              <label for="tue">Martedì</label>
            </div>
            <div>
              <input type="checkbox" id="wed" formControlName="wed">
              <label for="wed">Mercoledì</label>
            </div>
            <div>
              <input type="checkbox" id="thu" formControlName="thu">
              <label for="thu">Giovedì</label>
            </div>
            <div>
              <input type="checkbox" id="fri" formControlName="fri">
              <label for="fri">Venerdì</label>
            </div>
            <div>
              <input type="checkbox" id="sat" formControlName="sat">
              <label for="sat">Sabato</label>
            </div>
          </div>
        </div>
        <div formGroupName="hours">
          <p class="category">Orari di disponibilità</p>
          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="mor" formControlName="mor">
              <label for="mor">Mattino</label>
            </div>
            <div>
              <input type="checkbox" id="aft" formControlName="aft">
              <label for="aft">Pomeriggio</label>
            </div>
            <div>
              <input type="checkbox" id="eve" formControlName="eve">
              <label for="eve">Sera</label>
            </div>
          </div>
        </div>
        <div formGroupName="contract">
          <p class="category">Interessi per incarichi</p>
          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="occ" formControlName="occ">
              <label for="occ">Occasionali</label>
            </div>
            <div>
              <input type="checkbox" id="part" formControlName="part">
              <label for="part">Part-time</label>
            </div>
            <div>
              <input type="checkbox" id="full" formControlName="full">
              <label for="full">Full-time</label>
            </div>
          </div>
        </div>
      </div>

      <div style="display: flex;flex-direction:column;gap:15px">
        <div formGroupName="lang">
          <p class="category">Lingue</p>
          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="english" formControlName="english">
              <label for="english">Inglese</label>
            </div>
            <div>
              <input type="checkbox" id="italian" formControlName="italian">
              <label for="italian">Italiano</label>
            </div>
            <div>
              <input type="checkbox" id="spanish" formControlName="spanish">
              <label for="spanish">Spagnolo</label>
            </div>
          </div>
          <p class="category pt-3">Madrelingua</p>
          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="englishAdvanced" formControlName="englishAdvanced">
              <label for="englishAdvanced">Inglese madrelingua</label>
            </div>
            <div>
              <input type="checkbox" id="italianAdvanced" formControlName="italianAdvanced">
              <label for="italianAdvanced">Italiano madrelingua</label>
            </div>
            <div>
              <input type="checkbox" id="spanishAdvanced" formControlName="spanishAdvanced">
              <label for="spanishAdvanced">Spagnolo madrelingua</label>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showAgeFilter">
        <p class="category">Età</p>
        <div style="display: flex; gap: 15px;">
          <input type="number" min="1" max="99" formControlName="minAge" placeholder="Da...">
          <input type="number" min="1" max="99" formControlName="maxAge" placeholder="A...">
        </div>
      </div>
      <div>
        <p class="category">Distanza massima in KM</p>
        <input type="number" id="distance" formControlName="distance" placeholder="fino a...">
      </div>
    </form>
    <div style="display: grid;grid-template-columns: repeat(3, 1fr);">
      <p class="my-button secondary-button" (click)="resetFilters()">Reset</p>
      <p class="my-button" (click)="emitFilter()">Filtra</p>
    </div>
  </div>
</div>

