import { NpsTrackingService } from './nps-service/nps-tracking.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { Request } from 'npx-family-happy-common';
import { catchError, debounceTime, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { ToastComponent } from 'src/app/shared/components/toast/toast.component';
import { ToastService } from 'src/app/shared/services/toast-service/toast.service';

@Component({
  selector: 'app-request-nps',
  standalone: true,
  imports: [NgbRatingModule, ReactiveFormsModule, MatExpansionModule, ReactiveFormsModule],
  templateUrl: './request-nps.component.html',
  styleUrl: './request-nps.component.scss'
})
export class RequestNpsComponent implements OnInit{

  @Input() request!:Request

  rating = 0
  readonly = false
  noRate!: boolean
  toast!: ToastComponent | null;
  save = false;
  oldNotes!:string
  private unsubscribe = new Subject<void>()

  form = new FormGroup({
    notesControl: new FormControl(''),
  })


  constructor(private npsTrackingService:NpsTrackingService, private toastService:ToastService){}

  ngOnInit(): void {
    this.toastService.getChildComponent().pipe(takeUntil(this.unsubscribe)).subscribe((component) => this.toast = component)
    this.npsTrackingService.getReqNPS(this.request.id).pipe(
      tap((res) => {
        if(res !== null){
          if(res.score !== null){
            this.rating = res.score
            this.readonly = true
            this.form.get('notesControl')?.setValue(res.notes)
            this.oldNotes = res.notes
          }
        } else {
          this.noRate = true
        }
      }),
      takeUntil(this.unsubscribe),
    ).subscribe()

    this.form.get('notesControl')!.valueChanges.pipe(
      debounceTime(300),
      tap((value) => {
        if(this.oldNotes !== value){
          this.save = true
        } else {
          this.save = false
        }
      }),
      takeUntil(this.unsubscribe)
    ).subscribe()
  }

  test(rate:number){
    if(this.noRate === true){
      let note = this.form.get('notesControl')!.value
      this.npsTrackingService.createNPS(this.request, rate, note).pipe(
        tap(() => {
          this.toastService.setAction('Punteggio NPS aggiornato con successo!', true)
        }),
        catchError(() => {
          this.toastService.setAction("Errore durante l'aggiornamento del punteggio NPS", false)
          return of()
        }),
        takeUntil(this.unsubscribe)
      ).subscribe()
    } else {
      console.log(this.rating, "ALREADY EXIST")
    }
  }
  saveNotes(){
    let note = this.form.get('notesControl')!.value
    this.npsTrackingService.createNPS(this.request, this.rating, note).pipe(
      tap(() => {
        this.toastService.setAction('Punteggio NPS aggiornato con successo!', true)
        this.save = false
      }),
      catchError(() => {
        this.toastService.setAction("Errore durante l'aggiornamento del punteggio NPS", false)
        return of()
      }),
      takeUntil(this.unsubscribe)
    ).subscribe()
  }

  getRatingColor(value: number): string {
    if (value <= 6) {
      const maxRed = 6;
      const percent = value / maxRed;
      const red = 255;
      const green = Math.round(255 * percent/10);
      return `rgb(${red}, ${green}, 0)`;
    } else if (value <= 8) {
      // Da 7 a 8: Scala di giallo
      const minYellow = 7;
      // const percent = (value - minYellow) / 2;
      const percent = (value / minYellow) / 2
      const red = 255;
      const green = Math.round(255 * (percent));
      return `rgb(${red}, ${green}, 0)`;
    } else {
      // Da 9 a 10: Scala di verde
      const minGreen = 9;
      const percent = (value / minGreen) / 2;
      const red = 0;
      const green = Math.round(255 * (percent));
      return `rgb(${red}, ${green}, 0)`;
    }
  }
}
