import { BabysitterState, RouterStateManagerService } from '../../../shared/services/router-state-manager/router-state-manager.service';
import { Babysitter, CertificateService, CvTemplateComponent, FillCertificateConfig, ModalService, ProvinceFilterComponent } from 'npx-family-happy-common';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { BabySitterService } from '../baby-sitter-service/baby-sitter-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, of, Subject, takeUntil, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import html2canvas from 'html2canvas';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { Loader } from '@googlemaps/js-api-loader';
import { CaregiverScoreService } from 'src/app/shared/components/caregiver-score/caregiver-score.service';
import { CaregiverScoreType } from 'src/app/shared/models/caregiver-score.model';
import { CaregiverEvaluation, DEFAULT_CAREGIVER_EVALUATION } from 'src/app/shared/models/caregiver-evaluation.model';
import { EvaluationService } from 'src/app/shared/services/evaluation-service/evaluation.service';
import { CaregiverEvaluationRecord } from 'src/app/shared/models/caregiver-evaluation-record.model';
import { UserStorageService } from 'src/app/authentication/user-storage/user-storage.service';
import { GoogleMapsService } from 'src/app/requests-handling/caregiver-scoreboard/caregiver-selection/caregiver-selection-service/google-maps.service';
import { AlertComponent } from 'src/app/shared/components/modals/alert/alert.component';
import { CaregiverEvaluationComponent } from 'src/app/shared/components/caregiver-evaluation/caregiver-evaluation.component';
import { CaregiverEngagementComponent } from 'src/app/shared/components/caregiver-engagement/caregiver-engagement.component';
import { ImageCropperComponent } from 'src/app/shared/components/image-cropper/image-cropper.component';
import { DEFAULT_CAREGIVER_ENGAGEMENT } from 'src/app/shared/models/caregiver-engagement.model';
import { HttpClient } from '@angular/common/http';
import { ToastService } from 'src/app/shared/services/toast-service/toast.service';


@Component({
  selector: 'app-baby-sitter-detail',
  templateUrl: './baby-sitter-detail.component.html',
  styleUrls: ['./baby-sitter-detail.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, ProvinceFilterComponent, AlertComponent, CvTemplateComponent, CaregiverEvaluationComponent,
    CaregiverEngagementComponent, ImageCropperComponent]
})
export class BabySitterDetailComponent implements OnInit, AfterViewInit, OnDestroy {
  isNew = true;
  hasPayed!: boolean;
  hasDisostruzione!: boolean;
  addressSelected: boolean = false;
  showAlert = false;
  showModal = false;
  chosenPhoto!: string;
  finalPhoto!: string;
  step = 0;
  form!: UntypedFormGroup;
  babySitter!: Babysitter;
  babysitterID!: string;
  generatePages: boolean = false;
  cvPages!: number;
  currentPage: number = 0;
  canvases: HTMLCanvasElement[] = [];
  private nextTab = -1;
  private babysitterState!: BabysitterState;
  private goBackRequested = false;
  private caregiverRating: CaregiverEvaluation | undefined;
  private unsubscribe = new Subject<void>();
  private currentUser: string = '';
  private oldAddress!: string | undefined

  coursesForm = new FormGroup({
    courses: new FormControl('')
  })

  //default option for NEW BB
  defaultRating = DEFAULT_CAREGIVER_EVALUATION
  defaultEngagement = DEFAULT_CAREGIVER_ENGAGEMENT

  constructor(
    private certificateService: CertificateService,
    private babySitterService: BabySitterService,
    private stateManager: RouterStateManagerService,
    private sanitizer: DomSanitizer,
    private modalService: ModalService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private userStorage: UserStorageService,
    private caregiverScoreService: CaregiverScoreService,
    private caregiverEvaluationService: EvaluationService,
    private googleMapsService: GoogleMapsService,
    private http:HttpClient,
    private toastService:ToastService
  ) {
    this.finalPhoto = '';
  }

  ngOnInit(): void {
    this.userStorage.getUser().pipe(takeUntil(this.unsubscribe)).subscribe(user => {
      this.currentUser = user?.name ?? '';
    })
    this.form = new UntypedFormGroup({
      info: new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        surname: new UntypedFormControl('', Validators.required),
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        phone: new UntypedFormControl(null, Validators.required),
        address: new UntypedFormControl(''),
        city: new UntypedFormControl(''),
        zone: new UntypedFormControl(''),
        cap: new UntypedFormControl('', [Validators.pattern(new RegExp('^\\d{5}$'))]),
        age: new UntypedFormControl(0),
        birthDate: new UntypedFormControl(''),
        provincia: new UntypedFormControl('TO'),
        fiscalcode: new UntypedFormControl('', [Validators.pattern('^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$')]),
        iban: new UntypedFormControl(''),
        resume: new UntypedFormControl(''),
        resumeHidden: new UntypedFormControl(''),
        video: new UntypedFormControl(''),
        notes: new UntypedFormControl(''),
        description: new UntypedFormControl(''),
        photo: new UntypedFormControl(''),
        subscription: new UntypedFormControl(''),
        manualSubscription: new UntypedFormControl(''),
        expDate: new UntypedFormControl(''),
        expDateManual: new UntypedFormControl(''),
        stopWhatsapp: new UntypedFormControl(false),
        latitude: new UntypedFormControl(null),
        longitude: new UntypedFormControl(null)
      }),
      exp: new UntypedFormGroup({
        total: new UntypedFormControl(0),
        cluster1: new UntypedFormGroup({
          expYears: new UntypedFormControl(0, [Validators.min(0), Validators.max(50)]),
          employer: new UntypedFormControl(''),
          tasks: new UntypedFormGroup({
            hygiene: new UntypedFormControl(false),
            sleep: new UntypedFormControl(false),
            food: new UntypedFormControl(false),
            weaning: new UntypedFormControl(false),
            foodSafety: new UntypedFormControl(false),
            other: new UntypedFormControl(false)
          }),
          otherTasks: new UntypedFormArray([
            new UntypedFormControl('')
          ])
        }),
        cluster2: new UntypedFormGroup({
          expYears: new UntypedFormControl(0, [Validators.min(0), Validators.max(50)]),
          employer: new UntypedFormControl(''),
          tasks: new UntypedFormGroup({
            cry: new UntypedFormControl(false),
            crawling: new UntypedFormControl(false),
            montessori: new UntypedFormControl(false),
            language: new UntypedFormControl(false),
            foodSafety: new UntypedFormControl(false),
            motory: new UntypedFormControl(false),
            other: new UntypedFormControl(false)
          }),
          otherTasks: new UntypedFormArray([
            new UntypedFormControl('')
          ])
        }),
        cluster3: new UntypedFormGroup({
          expYears: new UntypedFormControl(0, [Validators.min(0), Validators.max(50)]),
          employer: new UntypedFormControl(''),
          tasks: new UntypedFormGroup({
            sleep: new UntypedFormControl(false),
            illness: new UntypedFormControl(false),
            motoryActivities: new UntypedFormControl(false),
            emotionManagement: new UntypedFormControl(false),
            spannolinamento: new UntypedFormControl(false),
            creativeGames: new UntypedFormControl(false),
            other: new UntypedFormControl(false)
          }),
          gamesDesc: new UntypedFormArray([
            new UntypedFormControl('')
          ]),
          otherTasks: new UntypedFormArray([
            new UntypedFormControl('')
          ])
        }),
        cluster4: new UntypedFormGroup({
          expYears: new UntypedFormControl(0, [Validators.min(0), Validators.max(50)]),
          employer: new UntypedFormControl(''),
          tasks: new UntypedFormGroup({
            homeworks: new UntypedFormControl(false),
            freeTime: new UntypedFormControl(false),
            extra: new UntypedFormControl(false),
            other: new UntypedFormControl(false)
          }),
          freeTimeDesc: new UntypedFormArray([
            new UntypedFormControl('')
          ]),
          otherTasks: new UntypedFormArray([
            new UntypedFormControl('')
          ])
        }),
        homework: new UntypedFormGroup({
          homeworkExp: new UntypedFormControl(0, [Validators.min(0), Validators.max(50)]),
          childAge1: new UntypedFormControl(0, [Validators.min(0), Validators.max(15)]),
          childAge2: new UntypedFormControl(0, [Validators.min(0), Validators.max(15)]),
          childAge3: new UntypedFormControl(0, [Validators.min(0), Validators.max(15)]),
          subjects: new UntypedFormGroup({
            math: new UntypedFormControl(false),
            italian: new UntypedFormControl(false),
            english: new UntypedFormControl(false),
            french: new UntypedFormControl(false),
            all: new UntypedFormControl(false),
            other: new UntypedFormControl(false),
            otherDesc: new UntypedFormControl('')
          })
        })
      }),
      reference: new UntypedFormGroup({
        f_surname: new UntypedFormControl(''),
        f_second_surname: new UntypedFormControl('')
      }),
      lang: new UntypedFormGroup({
        title: new UntypedFormControl(''),
        graduationType: new UntypedFormControl(''),
        specialities: new UntypedFormGroup({
          autistic: new UntypedFormControl(false),
          dislessia: new UntypedFormControl(false),
          doula: new UntypedFormControl(false),
        }),
        english: new UntypedFormControl(''),
        french: new UntypedFormControl(''),
        italian: new UntypedFormControl(''),
        german: new UntypedFormControl(''),
        spanish: new UntypedFormControl(''),
        certificates: new UntypedFormGroup({
          disostruzione: new UntypedFormControl(false),
          disostruzioneCertURL: new UntypedFormControl(''),
          emotiva: new UntypedFormControl(false),
          others: new UntypedFormArray([
            new UntypedFormControl('')
          ])
        })
      }),
      avail: new UntypedFormGroup({
        babysitter: new UntypedFormControl(false),
        homework: new UntypedFormControl(false),
        cleaning: new UntypedFormControl(false),
        cooking: new UntypedFormControl(false),
        taxi: new UntypedFormControl(false),
        taxiNoCar: new UntypedFormControl(false),
        walking: new UntypedFormControl(false),
        petsitter: new UntypedFormControl(false),
        licence: new UntypedFormControl(false),
        car: new UntypedFormControl(false),
        smoker: new UntypedFormControl(false),
        coliving: new UntypedFormControl(false),
        relocation: new UntypedFormControl(false),
        days: new UntypedFormGroup({
          sun: new UntypedFormControl(false),
          mon: new UntypedFormControl(false),
          tue: new UntypedFormControl(false),
          wed: new UntypedFormControl(false),
          thu: new UntypedFormControl(false),
          fri: new UntypedFormControl(false),
          sat: new UntypedFormControl(false),
        }),
        hours: new UntypedFormGroup({
          mor: new UntypedFormControl(false),
          aft: new UntypedFormControl(false),
          eve: new UntypedFormControl(false),
        }),
        contract: new UntypedFormGroup({
          occ: new UntypedFormControl(false),
          part: new UntypedFormControl(false),
          full: new UntypedFormControl(false),
        })
      })
    });
    this.activeRoute.params.subscribe(param => {
      this.babysitterID = param.id;
    })
    /* this.subscriptions.push(this.form.valueChanges.subscribe((value) => {
      this.setBabysitter(this.processFormData(value));
    })); */
    try {
      this.stateManager.getBabysitterState().pipe(takeUntil(this.unsubscribe)).subscribe((state) => {
        this.babysitterState = state;
        this.babysitterState.id = this.babysitterID;
        this.babysitterState.route = 'babysitter-detail/' + this.babysitterID;
        this.oldAddress = state.babysitter?.info.address //GET OLD ADDRESS FROM BE (TO COMPARE WITH ADDRESS ON FORM)
        if (this.babysitterState.step >= 0) {
          this.step = this.babysitterState.step;
        }
        if (this.babysitterState.babysitter) {
          this.isNew = false;
          this.setBabysitter(this.babysitterState.babysitter);
          this.formInitialize(this.babysitterState.babysitter);
        } else if (!this.babysitterID.match('null')) {
          const id = this.babysitterID;
          if (id && id !== 'new') {
            this.isNew = false;
            this.babySitterService.getSingleBabysitter(id).pipe(takeUntil(this.unsubscribe)).subscribe((babysitters) => {
              let babysitter = babysitters[0];
              if (babysitter) {
                this.setBabysitter(babysitter);
                this.formInitialize(this.babySitter);
              }
            });
          }
        }
      });
    } catch (err) {
      this.modalService.showErrorMessage('Errore nel recupero delle informazioni legate alla babysitter.');
    }

    this.form.get('lang.title')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (value === 'Laurea') {
        this.form.get('lang.graduationType')?.enable({ emitEvent: false });
      } else {
        this.form.get('lang.graduationType')?.disable({ emitEvent: false });
      }
    });

    /* Reset exp clusters when value is 0 */

    this.form.get('exp.cluster1.expYears')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (!value) {
        this.form.get('exp.cluster1')?.reset(undefined, { emitEvent: false });
        this.resetFormArray(1, 'otherTasks');
        this.form.get('exp.cluster1.expYears')?.setValue(0, { emitEvent: false });
      }
    });
    this.form.get('exp.cluster2.expYears')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (!value) {
        this.form.get('exp.cluster2')?.reset(undefined, { emitEvent: false });
        this.resetFormArray(2, 'otherTasks');
        this.form.get('exp.cluster2.expYears')?.setValue(0, { emitEvent: false });
      }
    });
    this.form.get('exp.cluster3.expYears')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (!value) {
        this.form.get('exp.cluster3')?.reset(undefined, { emitEvent: false });
        this.resetFormArray(3, 'gamesDesc');
        this.resetFormArray(3, 'otherTasks');
        this.form.get('exp.cluster3.expYears')?.setValue(0, { emitEvent: false });
      }
    });
    this.form.get('exp.cluster4.expYears')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (!value) {
        this.form.get('exp.cluster4')?.reset(undefined, { emitEvent: false });
        this.resetFormArray(4, 'freeTimeDesc');
        this.resetFormArray(4, 'otherTasks');
        this.form.get('exp.cluster4.expYears')?.setValue(0, { emitEvent: false });
      }
    });

    /* FormArrays handling depending on relative checkbox status */

    this.form.get('exp.cluster1.tasks.6')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (!value) {
        this.resetFormArray(1, 'otherTasks');
      }
    });
    this.form.get('exp.cluster2.tasks.7')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (!value) {
        this.resetFormArray(2, 'otherTasks');
      }
    });
    this.form.get('exp.cluster3.tasks.6')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (!value) {
        this.resetFormArray(3, 'gamesDesc');
      }
    });
    this.form.get('exp.cluster3.tasks.7')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (!value) {
        this.resetFormArray(3, 'otherTasks');
      }
    });
    this.form.get('exp.cluster4.tasks.2')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (!value) {
        this.resetFormArray(4, 'freeTimeDesc');
      }
    });
    this.form.get('exp.cluster4.tasks.4')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (!value) {
        this.resetFormArray(4, 'otherTasks');
      }
    });

    //Listen to every cluster's expYears value changes and then compute the total exp according to the formula

    this.form.get('exp.cluster1.expYears')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      let exp = this.form.get('exp')?.value;
      let expSum = exp.cluster1.expYears + exp.cluster2.expYears + exp.cluster3.expYears + exp.cluster4.expYears;
      let age = this.form.get('info.age')?.value;
      this.form.get('exp.total')?.setValue(Math.max(exp.cluster1.expYears, exp.cluster2.expYears, exp.cluster3.expYears, exp.cluster4.expYears, Math.min(expSum, (age - 16))), { emitEvent: false });
    });

    this.form.get('exp.cluster2.expYears')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      let exp = this.form.get('exp')?.value;
      let expSum = exp.cluster1.expYears + exp.cluster2.expYears + exp.cluster3.expYears + exp.cluster4.expYears;
      let age = this.form.get('info.age')?.value;
      this.form.get('exp.total')?.setValue(Math.max(exp.cluster1.expYears, exp.cluster2.expYears, exp.cluster3.expYears, exp.cluster4.expYears, Math.min(expSum, (age - 16))), { emitEvent: false });
    });

    this.form.get('exp.cluster3.expYears')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      let exp = this.form.get('exp')?.value;
      let expSum = exp.cluster1.expYears + exp.cluster2.expYears + exp.cluster3.expYears + exp.cluster4.expYears;
      let age = this.form.get('info.age')?.value;
      this.form.get('exp.total')?.setValue(Math.max(exp.cluster1.expYears, exp.cluster2.expYears, exp.cluster3.expYears, exp.cluster4.expYears, Math.min(expSum, (age - 16))), { emitEvent: false });
    });

    this.form.get('exp.cluster4.expYears')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      let exp = this.form.get('exp')?.value;
      let expSum = exp.cluster1.expYears + exp.cluster2.expYears + exp.cluster3.expYears + exp.cluster4.expYears;
      let age = this.form.get('info.age')?.value;
      this.form.get('exp.total')?.setValue(Math.max(exp.cluster1.expYears, exp.cluster2.expYears, exp.cluster3.expYears, exp.cluster4.expYears, Math.min(expSum, (age - 16))), { emitEvent: false });
    });
  }

  ngAfterViewInit(): void {
    /* Google Maps */
    let inputAddress = document.getElementById('address') as HTMLInputElement;

    if (inputAddress) {
      inputAddress.addEventListener('change', () => {
        inputAddress.value = '';
        this.form.get('info.address')?.setValue('', { emitEvent: false });
      });
      const loader = new Loader({ apiKey: environment.googleMapsAPIKey, version: "weekly", libraries: ["places"], language: 'it-IT' }).load().then((google) => {

        const autocomplete = new google.maps.places.Autocomplete(inputAddress);

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          let formAddress = this.form.get('info.address');

          formAddress?.setValue(place.formatted_address);

          // Compile other address fields according to place object
          place.address_components?.forEach((component: any) => {
            if (component.types.includes('locality')) {
              this.form.get('info.city')?.setValue(component.long_name);
            }
            if (component.types.includes('postal_code')) {
              this.form.get('info.cap')?.setValue(component.long_name);
            }
          });
        })
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.stateManager.setBabysitterState(this.babysitterState, false);
    this.processFormData(this.form.value).then((res) => {
      this.setBabysitter(res);
    })
  }
  //GET CHILDAGE ON HOMEWORK
  get childAge1() {
    return this.form.get('exp.homework.childAge1');
  }
  get childAge2() {
    return this.form.get('exp.homework.childAge2');
  }
  get childAge3() {
    return this.form.get('exp.homework.childAge3');
  }

  uploadFile(_event: any, field: string) {
    try {
      this.modalService.showLoadingWithMessage('Caricamento CV in corso...');
      const file = <File>_event.target.files[0];
      const path = field + '/' + field + this.form.get('info.email')?.value + '.pdf';
      let storageRef = ref(getStorage(), path);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on('state_changed', () => { }, () => { }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          if (field === 'info.resume') {
            this.babySitter.info.resume = downloadURL;
          } else {
            this.babySitter.info.resumeHidden = downloadURL;
          }
          this.form.get(field)?.setValue(downloadURL);
          if (this.isNew) {
            this.modalService.hideLoading();
            this.createBabysitter();
            this.modalService.showSuccessMessage('Caricamento riuscito.');
          } else {
            this.updateBabysitter();
            this.modalService.showSuccessMessage('Caricamento riuscito.');
          }
        });
      })
    } catch (err) {
      this.modalService.hideLoading();
      this.modalService.showErrorMessage('Errore nel caricamento del CV. Si prega di riprovare');
    }
  }

  uploadImage(image: string, id: string) {
    try {
      this.modalService.showLoadingWithMessage('Caricamento foto in corso...');
      const file = this.base64ToBlob(image.replace('data:image/png;base64,', ''), "image/png");
      const path = 'pictures/' + id + '.png';
      let storageRef = ref(getStorage(), path);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on('state_changed', () => { }, () => { }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
          this.form.get('info.photo')?.setValue(downloadURL);
          let body = this.babySitter.info;
          body.photo = downloadURL;
          this.babySitter.info = body;
          if (this.isNew) {
            this.createBabysitter();
            this.modalService.hideLoading();
            this.modalService.showSuccessMessage('Caricamento riuscito.');
          } else {
            this.updateBabysitter();
            this.modalService.hideLoading();
            this.modalService.showSuccessMessage('Caricamento riuscito.');
          }
        });
      })
    } catch (err) {
      this.modalService.hideLoading();;
      this.modalService.showErrorMessage('Errore nel corso del caricamento della foto. Si prega di riprovare.');
    }
    this.showModal = false;
  }

  base64ToBlob(base64Data: string, contentType: string) {
    contentType = contentType || '';
    let sliceSize = 1024;
    let byteCharacters = atob(base64Data);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);

      let bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  async generateCV(showAlert: boolean) {
    if (this.form.invalid) {
      return
    }
    if (showAlert) {
      this.modalService.showLoadingWithMessage('Generazione CV Family Happy in corso...');
    }
    try {
      this.generatePages = true;
      setTimeout(() => { this.htmlToCanvas(showAlert) }, 1000);
      //await this.certificateService.createCVPDF(this.babySitter, this.isNew, showAlert);
    } catch (err: any) {
      this.modalService.hideLoading();
      this.modalService.showErrorMessage('Errore nella generazione del CV. Potrebbe essere legato alla foto caricata. Si prega di scaricarla, caricarla nuovamente e riprovare.');
    }
  }

  goBack() {
    if (this.form.dirty) {
      this.showAlert = true;
      this.goBackRequested = true;
    } else {
      this.babysitterState.route = 'babysitter-list';
      this.babysitterState.babysitter = undefined;
      this.babysitterState.step = 0;
      this.babysitterState.id = 'null';
      this.ngOnDestroy();
      this.stateManager.setBabysitterState(this.babysitterState);
    }
  }

  formInitialize(babysitter: Babysitter) {
    let cluster1Tasks = this.form.get('exp.cluster1.tasks')?.value;
    babysitter.exp.cluster1.tasks1.forEach(task => cluster1Tasks[task] = true);

    let cluster2Tasks = this.form.get('exp.cluster2.tasks')?.value;
    babysitter.exp.cluster2.tasks2.forEach(task => cluster2Tasks[task] = true);

    let cluster3Tasks = this.form.get('exp.cluster3.tasks')?.value;
    babysitter.exp.cluster3.tasks3.forEach(task => cluster3Tasks[task] = true);

    let cluster4Tasks = this.form.get('exp.cluster4.tasks')?.value;
    babysitter.exp.cluster4.tasks4.forEach(task => cluster4Tasks[task] = true);

    let expSum = babysitter.exp.cluster1.cluster1Age + babysitter.exp.cluster2.cluster2Age + babysitter.exp.cluster3.cluster3Age + babysitter.exp.cluster4.cluster4Age;
    let age = babysitter.info.age;

    this.resetAllFormArrays();

    if (babysitter) {
      this.form.setValue({
        info: {
          ...babysitter.info,
          expDate: babysitter.info.expDate ? this.datePipe.transform(babysitter.info.expDate, 'yyyy-MM-dd') : '',
          expDateManual: babysitter.info.expDateManual ? this.datePipe.transform(babysitter.info.expDateManual, 'yyyy-MM-dd') : ''
        },
        exp: {
          total: babysitter.exp.total,
          cluster1: {
            expYears: babysitter.exp.cluster1.cluster1Age,
            employer: babysitter.exp.cluster1.employer1,
            tasks: cluster1Tasks,
            otherTasks: ['']
          },
          cluster2: {
            expYears: babysitter.exp.cluster2.cluster2Age,
            employer: babysitter.exp.cluster2.employer2,
            tasks: cluster2Tasks,
            otherTasks: ['']
          },
          cluster3: {
            expYears: babysitter.exp.cluster3.cluster3Age,
            employer: babysitter.exp.cluster3.employer3,
            tasks: cluster3Tasks,
            gamesDesc: [''],
            otherTasks: ['']
          },
          cluster4: {
            expYears: babysitter.exp.cluster4.cluster4Age,
            employer: babysitter.exp.cluster4.employer4,
            tasks: cluster4Tasks,
            freeTimeDesc: [''],
            otherTasks: ['']
          },
          homework: {
            homeworkExp: babysitter.exp.experienceHomework ? babysitter.exp.experienceHomework.expYears : null,
            childAge1: babysitter.exp.experienceHomework ? babysitter.exp.experienceHomework.firstChildAge : null,
            childAge2: babysitter.exp.experienceHomework ? babysitter.exp.experienceHomework.secondChildAge : null,
            childAge3: babysitter.exp.experienceHomework ? babysitter.exp.experienceHomework.thirdChildAge : null,
            subjects: {
              math: babysitter.exp.experienceHomework.subjects ? babysitter.exp.experienceHomework.subjects.math : false,
              italian: babysitter.exp.experienceHomework.subjects ? babysitter.exp.experienceHomework.subjects.ita : false,
              english: babysitter.exp.experienceHomework.subjects ? babysitter.exp.experienceHomework.subjects.eng :false,
              french: babysitter.exp.experienceHomework.subjects ? babysitter.exp.experienceHomework.subjects.fr : false,
              all: babysitter.exp.experienceHomework.subjects ? babysitter.exp.experienceHomework.subjects.homeworks_all : false,
              other: babysitter.exp.experienceHomework.subjects ? babysitter.exp.experienceHomework.subjects.homeworks_other : false,
              otherDesc: babysitter.exp.experienceHomework.subjects ? babysitter.exp.experienceHomework.subjects.otherDesc : ''
            }
          }
        },
        // reference: babysitter.reference,
        reference:{
          f_surname: babysitter.reference ? babysitter.reference.f_surname : '',
          f_second_surname: babysitter.reference ? babysitter.reference.f_second_surname : '',
        },
        lang: {
          ...babysitter.lang,
          certificates: {
            disostruzione: babysitter.certificates.disostruzione,
            disostruzioneCertURL: babysitter.certificates.disostruzioneCertURL,
            emotiva: babysitter.certificates.emotiva,
            others: ['']
          }
        },
        avail: {
          babysitter: babysitter.avail.babysitter,
          homework: babysitter.avail.homework,
          cleaning: babysitter.avail.cleaning,
          cooking: babysitter.avail.cooking,
          taxi: babysitter.avail.taxi,
          taxiNoCar: babysitter.avail.taxiNoCar,
          walking: babysitter.avail.walking,
          petsitter: babysitter.avail.petSitter,
          licence: babysitter.avail.licence,
          car: babysitter.avail.car,
          smoker: babysitter.avail.smoker,
          coliving: babysitter.avail.coliving,
          relocation: babysitter.avail.relocation,
          days: this.babySitterService.fromStringToAvailObject(babysitter.avail.days, 'days'),
          hours: this.babySitterService.fromStringToAvailObject(babysitter.avail.hours, 'hours'),
          contract: this.babySitterService.fromStringToAvailObject(babysitter.avail.contract, 'contract')
        }
      }, { emitEvent: false });

      this.setFormArrayValue(1, 'otherTasks', babysitter.exp.cluster1.extraTasks1);
      this.setFormArrayValue(2, 'otherTasks', babysitter.exp.cluster2.extraTasks2);
      this.setFormArrayValue(3, 'gamesDesc', babysitter.exp.cluster3.plays3);
      this.setFormArrayValue(3, 'otherTasks', babysitter.exp.cluster3.extraTasks3);
      this.setFormArrayValue(4, 'freeTimeDesc', babysitter.exp.cluster4.freeTime4);
      this.setFormArrayValue(4, 'otherTasks', babysitter.exp.cluster4.extraTasks4);
      this.setCertificatesLenght(babysitter.certificates.others);
    }

    this.hasDisostruzione = babysitter.certificates.disostruzione;
  }

  save(showMessage?: boolean) {
    if (this.form.valid) {
      if (this.isNew) {
        this.createBabysitter();
      } else {
        this.updateBabysitter(showMessage);
      }
    }
  }

  changeTab(tab: number) {
    if (this.form.dirty) {
      this.showAlert = true;
      this.nextTab = tab;
    } else {
      this.step = tab;
      this.babysitterState.step = this.step;
    }
  }

  onAlertAction(save: boolean) {
    if (save && !this.isNew) {
      this.updateBabysitter();
    } else if (save && this.isNew) {
      this.createBabysitter();
    } else if (!save && this.goBackRequested) {
      if (this.babySitter === undefined) {
        this.form.markAsPristine();
        this.form.reset();
        this.goBack();
      } else {
        this.form.markAsPristine();
        this.form.reset();
        this.formInitialize(this.babySitter);
        this.goBack();
      }
    } else {
      this.form.markAsPristine();
      this.form.reset();
      this.formInitialize(this.babySitter);
      // this.goBack();
    }
    this.showAlert = false;
    if (this.nextTab !== -1) {
      this.step = this.nextTab;
      this.babysitterState.step = this.step;
      this.nextTab = -1;
    }
  }

  private async createBabysitter() {
    this.babySitter = await this.processFormData(this.form.value);
    this.babySitterService.createBabySitter(this.babySitter).pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (res: any) => {
        this.modalService.showSuccessMessage('Nuova babysitter creata.');
        const regex = /#(\w{2}-\d{5})/gm;
        const result = regex.exec(res.message);
        if (result) {
          this.babySitterService.getSingleBabysitter(result[1]).pipe(takeUntil(this.unsubscribe)).subscribe((babysitters) => {
            let babysitter = babysitters[0];
            if (babysitter) {
              this.isNew = !this.isNew;
              this.babySitter = babysitter;
              this.form.markAsPristine();
              this.formInitialize(this.babySitter);
            }
          });
        }
      },
      error: (error) => {
        if ((error.error as string).match('Key .* already exists.')) {
          this.modalService.showErrorMessage('Esiste già un profilo associato all\'indirizzo email fornito.');
        } else {
          this.modalService.showErrorMessage('Si è verificato un errore nella creazione della babysitter, si prega di riprovare.');
        }
      }
    });
  }

  private async updateBabysitter(showMessage?: boolean) {
    this.babySitter = await this.processFormData(this.form.value);

    if (this.babySitter.certificates.disostruzione && !this.hasDisostruzione) {
      this.caregiverScoreService.getScore(this.babySitter.docRef).subscribe((score) => {
        this.caregiverScoreService.updateScore(this.babySitter.docRef, score.disostuzioneCertified + 2, CaregiverScoreType.DISOSTRUZIONE_CERTIFIED).subscribe((res) => {
          console.log('Punteggio disostruzione aggiornato correttamente');
        })
      })
    }
    this.babySitterService.updateBabySitter(this.babySitter).pipe(takeUntil(this.unsubscribe)).subscribe({
      next: () => {
        if (showMessage) {
          this.modalService.showSuccessMessage('Babysitter modificata.');
        }
        this.publishNewCaregiverScoreUpdate();
        this.form.markAsPristine();
        this.formInitialize(this.babySitter);
        this.generateCV(false);
      },
      error: (error) => {
        console.log(error);
        this.modalService.showErrorMessage(`Si è verificato un errore nell'aggiornamento della babysitter.`);
      }
    });
  }

  private async processFormData(data: any) {
    let cluster1Tasks: string[] = [];
    Object.entries(data.exp.cluster1.tasks).forEach(([key, value]) => {
      if (value) {
        cluster1Tasks.push(key);
      } else {
        return;
      }
    });

    let cluster2Tasks: string[] = [];
    Object.entries(data.exp.cluster2.tasks).forEach(([key, value]) => {
      if (value) {
        cluster2Tasks.push(key);
      } else {
        return;
      }
    });

    let cluster3Tasks: string[] = [];
    Object.entries(data.exp.cluster3.tasks).forEach(([key, value]) => {
      if (value) {
        cluster3Tasks.push(key);
      } else {
        return;
      }
    });

    let cluster4Tasks: string[] = [];
    Object.entries(data.exp.cluster4.tasks).forEach(([key, value]) => {
      if (value) {
        cluster4Tasks.push(key);
      } else {
        return;
      }
    });

    let babysitter: Babysitter = {
      docRef: this.babySitter?.docRef ?? '',
      certificate: this.babySitter?.certificate ?? '',
      info: {
        name: data.info.name,
        surname: data.info.surname,
        email: data.info.email,
        phone: data.info.phone,
        address: data.info.address,
        city: data.info.city,
        zone: data.info.zone,
        cap: data.info.cap,
        age: +data.info.age > 0 ? +data.info.age : 1,
        birthDate: data.info.birthDate,
        provincia: data.info.provincia,
        fiscalcode: data.info.fiscalcode,
        iban: data.info.iban,
        resume: data.info.resume,
        resumeHidden: data.info.resumeHidden,
        video: data.info.video,
        notes: data.info.notes,
        description: data.info.description,
        photo: data.info.photo,
        subscription: data.info.subscription ?? '',
        manualSubscription: data.info.manualSubscription ?? '',
        expDate: data.info.expDate !== '' ? new Date(data.info.expDate) : null,
        expDateManual: data.info.expDateManual !== '' ? new Date(data.info.expDateManual) : null,
        stopWhatsapp: data.info.stopWhatsapp ?? false,
        latitude: data.info.latitude,
        longitude: data.info.longitude,
      },
      exp: {
        total: data.exp.total,
        cluster1: {
          cluster1Age: data.exp.cluster1.expYears ?? 0,
          employer1: data.exp.cluster1.employer,
          tasks1: cluster1Tasks,
          extraTasks1: data.exp.cluster1.otherTasks ?? null
        },
        cluster2: {
          cluster2Age: data.exp.cluster2.expYears ?? 0,
          employer2: data.exp.cluster2.employer,
          tasks2: cluster2Tasks,
          extraTasks2: data.exp.cluster2.otherTasks ?? null
        },
        cluster3: {
          cluster3Age: data.exp.cluster3.expYears ?? 0,
          employer3: data.exp.cluster3.employer,
          tasks3: cluster3Tasks,
          plays3: data.exp.cluster3.gamesDesc ?? null,
          extraTasks3: data.exp.cluster3.otherTasks ?? null
        },
        cluster4: {
          cluster4Age: data.exp.cluster4.expYears ?? 0,
          employer4: data.exp.cluster4.employer,
          tasks4: cluster4Tasks,
          freeTime4: data.exp.cluster4.freeTimeDesc ?? null,
          extraTasks4: data.exp.cluster4.otherTasks ?? null
        },
        experienceHomework: {
          expYears: data.exp.homework.homeworkExp,
          firstChildAge: data.exp.homework.childAge1 ?? 0,
          secondChildAge: data.exp.homework.childAge2 ?? 0,
          thirdChildAge: data.exp.homework.childAge3 ?? 0,
          subjects: {
            math: data.exp.homework.subjects.math,
            ita: data.exp.homework.subjects.italian,
            eng: data.exp.homework.subjects.english,
            fr: data.exp.homework.subjects.french,
            homeworks_all: data.exp.homework.subjects.all,
            homeworks_other: data.exp.homework.subjects.other,
            otherDesc: data.exp.homework.subjects.otherDesc ?? ''
          }
        }
      },
      reference: {
        f_surname: data.reference.f_surname,
        f_second_surname: data.reference.f_second_surname
      },
      lang: {
        title: data.lang.title,
        graduationType: data.lang.graduationType ?? '',
        specialities: {
          autistic: data.lang.specialities ? data.lang.specialities.autistic : false,
          dislessia: data.lang.specialities ? data.lang.specialities.dislessia : false,
          doula: data.lang.specialities ? data.lang.specialities.doula : false,
        },
        english: data.lang.english,
        french: data.lang.french,
        italian: data.lang.italian,
        german: data.lang.german,
        spanish: data.lang.spanish
      },
      avail: {
        babysitter: data.avail.babysitter,
        homework: data.avail.homework,
        cleaning: data.avail.cleaning,
        cooking: data.avail.cooking,
        taxi: data.avail.taxi,
        taxiNoCar: data.avail.taxiNoCar,
        walking: data.avail.walking,
        petSitter: data.avail.petsitter,
        licence: data.avail.licence,
        car: data.avail.car,
        smoker: data.avail.smoker,
        coliving: data.avail.coliving,
        relocation: data.avail.relocation,
        days: this.babySitterService.fromAvailObjectToString(data.avail.days, 'days'),
        hours: this.babySitterService.fromAvailObjectToString(data.avail.hours, 'hours'),
        contract: this.babySitterService.fromAvailObjectToString(data.avail.contract, 'contract')
      },
      rating: {
        ...this.caregiverRating ?? this.babySitter?.rating ??
        {
          overall: 0,
          punctuality: 0,
          empathy: 0,
          behave: 0,
          communication: 0,
          attitude: '',
          dangerous: false,
          notRated: false
        }
      },
      engagement: {
        ...this.babySitter?.engagement ?? {
          selected: 0,
          chosen: 0,
          discarded: 0,
          ignored: 0,
          substituted: 0,
          applications: 0,
          chosenDate: undefined,
          latestApplication: undefined
        }
      },
      certificates: {
        disostruzione: data.lang.certificates.disostruzione,
        disostruzioneCertURL: data.lang.certificates.disostruzioneCertURL,
        emotiva: data.lang.certificates.emotiva,
        others: data.lang.certificates.others
      },
      updateTimestamp: this.babySitter ? this.babySitter.updateTimestamp : new Date()
    };

    //calculate coordinates from address
    if (data.info.address === '') {
      let coordinates = {
        latitude: data.info.latitude,
        longitude: data.info.longitude,
      }
      babysitter = {
        ...babysitter,
        info: {
          ...babysitter.info,
          latitude: coordinates.latitude,
          longitude: coordinates.longitude
        }
      }
    } else {
      if (this.oldAddress !== data.info.address) {
        let coordinates = await this.getCoordiantes(babysitter)
        babysitter = {
          ...babysitter,
          info: {
            ...babysitter.info,
            latitude: coordinates.latitude,
            longitude: coordinates.longitude
          }
        }

      } else {
        let coordinates = {
          latitude: data.info.latitude,
          longitude: data.info.longitude,
        }
        babysitter = {
          ...babysitter,
          info: {
            ...babysitter.info,
            latitude: coordinates.latitude,
            longitude: coordinates.longitude
          }
        }
      }
    }
    return babysitter;
  }

  private setBabysitter(bb: Babysitter) {
    this.babySitter = {
      ...bb,
      exp: {
        ...bb.exp,
        experienceHomework:{
          expYears: bb.exp.experienceHomework ? bb.exp.experienceHomework.expYears : 0,
          firstChildAge: bb.exp.experienceHomework ? bb.exp.experienceHomework.firstChildAge : 0,
          secondChildAge: bb.exp.experienceHomework ? bb.exp.experienceHomework.secondChildAge : 0,
          thirdChildAge: bb.exp.experienceHomework ? bb.exp.experienceHomework.thirdChildAge : 0,
          subjects: {
            math: bb.exp.experienceHomework.subjects ? bb.exp.experienceHomework.subjects.math : false,
            ita: bb.exp.experienceHomework.subjects ? bb.exp.experienceHomework.subjects.ita : false,
            eng: bb.exp.experienceHomework.subjects ? bb.exp.experienceHomework.subjects.eng :false,
            fr: bb.exp.experienceHomework.subjects ? bb.exp.experienceHomework.subjects.fr : false,
            homeworks_all: bb.exp.experienceHomework.subjects ? bb.exp.experienceHomework.subjects.homeworks_all : false,
            homeworks_other: bb.exp.experienceHomework.subjects ? bb.exp.experienceHomework.subjects.homeworks_other : false,
            otherDesc: bb.exp.experienceHomework.subjects ? bb.exp.experienceHomework.subjects.otherDesc : ''
          }
        }
      },
      reference:{
        f_surname: bb.reference ? bb.reference.f_surname : '',
        f_second_surname: bb.reference ? bb.reference.f_second_surname : '',
      },
    }
    // this.babySitter = bb;
    this.babysitterState.babysitter = this.babySitter;
    this.babysitterState.step = this.step;
  }

  openModal(event: any) {
    let image = event.target.files[0];
    this.chosenPhoto = URL.createObjectURL(image);
    this.showModal = true;
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  dismiss(event: Event) {
    const elem = event.target as HTMLElement;
    if (elem === document.getElementById('modal-container')) {
      this.showModal = false;
    }
  }

  getCroppedPhoto(image: string) {
    this.finalPhoto = image;
  }

  cropperAbort() {
    let imageInput = <HTMLInputElement>document.getElementById("image-selection");
    imageInput.value = "";
    this.showModal = false;
  }

  /* Experience form arrays methods */

  changeFormArrayLength(more: boolean, cluster: number, field: string) {
    let array: UntypedFormArray = this.form.get(`exp.cluster${cluster}.${field}`) as UntypedFormArray;
    if (more) {
      array.push(new UntypedFormControl(''));
    } else {
      array.removeAt(array.length - 1);
    }
  }

  setFormArrayValue(cluster: number, field: string, data?: string[]) {
    let array: UntypedFormArray = this.form.get(`exp.cluster${cluster}.${field}`) as UntypedFormArray;

    if (data && data.length > 0) {
      array.clear();
      data!.forEach(data => array.push(new UntypedFormControl(data)));
    }
  }

  resetFormArray(cluster: number, field: string) {
    let array: UntypedFormArray = this.form.get(`exp.cluster${cluster}.${field}`) as UntypedFormArray;

    array.clear();
  }

  resetAllFormArrays() {
    this.resetFormArray(1, 'otherTasks');
    this.resetFormArray(2, 'otherTasks');
    this.resetFormArray(3, 'otherTasks');
    this.resetFormArray(4, 'otherTasks');
    this.resetFormArray(3, 'gamesDesc');
    this.resetFormArray(4, 'freeTimeDesc');
    this.resetCertificates();
    this.changeFormArrayLength(true, 1, 'otherTasks');
    this.changeFormArrayLength(true, 2, 'otherTasks');
    this.changeFormArrayLength(true, 3, 'otherTasks');
    this.changeFormArrayLength(true, 4, 'otherTasks');
    this.changeFormArrayLength(true, 3, 'gamesDesc');
    this.changeFormArrayLength(true, 4, 'freeTimeDesc');
    this.changeCertificatesLength(true);
  }

  getFormArrayControls(cluster: number, field: string) {
    let array: UntypedFormArray = this.form.get(`exp.cluster${cluster}.${field}`) as UntypedFormArray;

    return array.controls;
  }


  /* Certificates form arrays methods */

  changeCertificatesLength(more: boolean) {
    let array: UntypedFormArray = this.form.get('lang.certificates.others') as UntypedFormArray;

    if (more) {
      array.push(new UntypedFormControl(''));
    } else {
      array.removeAt(array.length - 1);
    }
  }

  setCertificatesLenght(data?: string[]) {
    let array: UntypedFormArray = this.form.get('lang.certificates.others') as UntypedFormArray;

    if (data && data.length > 0) {
      array.clear();
      data!.forEach(data => array.push(new UntypedFormControl(data)));
    }
  }

  resetCertificates() {
    let array: UntypedFormArray = this.form.get('lang.certificates.others') as UntypedFormArray;

    array.clear();
  }

  getCertificatesFormControls() {
    let array: UntypedFormArray = this.form.get('lang.certificates.others') as UntypedFormArray;

    return array.controls;
  }

  htmlToCanvas(showAlert: boolean) {
    if (this.currentPage >= this.cvPages) {
      this.currentPage = 0;
      this.generatePages = false;
      return;
    }
    const element = document.getElementById('cv-page-container') as HTMLElement;
    html2canvas(element, { allowTaint: true, useCORS: true }).then(canvas => {
      this.canvases.push(canvas);
      if (this.currentPage === this.cvPages - 1) {
        this.certificateService.createPDFCVFromHTML(this.canvases, this.babySitter, this.isNew, showAlert,
          this.stateManager, environment.babysitterServiceURL, environment.requestServiceURL);
        this.canvases = [];
      }
      this.currentPage++;
      setTimeout(() => { this.htmlToCanvas(showAlert) }, 1000);
    })
  }

  cvPreview() {
    if (!this.generatePages) {
      this.router.navigate(['babysitter-detail', this.babySitter.docRef, 'cv'], { state: { data: this.babySitter } });
    }
  }

  openCertificatePage() {
    const config: FillCertificateConfig = {
      babysitter: this.babySitter,
      stateManager: this.stateManager,
      isProduction: environment.production,
      mailServiceURL: environment.mailServiceURL,
      babysitterApiURL: environment.babysitterServiceURL,
      showModal: true
    }
    this.certificateService.setConfig(config);
    this.router.navigate(['babysitter-detail', this.babySitter.docRef, 'fill-certificate']);
  }

  computeTotalExp() {
    let value = this.form.get('exp')?.value;
    let expSum = value.cluster1.expYears + value.cluster2.expYears + value.cluster3.expYears + value.cluster4.expYears;
    let age = this.form.get('info.age')?.value;
    this.form.get('exp.total')?.setValue(Math.max(value.cluster1.expYears, value.cluster2.expYears, value.cluster3.expYears, value.cluster4.expYears, Math.min(expSum, (age - 16))), { emitEvent: false });
  }

  onCaregiverEvaluationChanged(newCaregiverEvaluation: CaregiverEvaluation) {
    this.caregiverRating = newCaregiverEvaluation;
    this.form.markAsTouched();
    this.form.markAsDirty();
  }

  updateProvincia(newValue: string) {
    this.form.get('info.provincia')?.setValue(newValue);
    this.form.markAllAsTouched();
    this.form.get('info.provincia')?.markAsDirty();
  }

  sendCoursesWhatsapp(){
    let courses = this.coursesForm.get('courses')
    if(courses!.value !== null && courses!.value !== '') {
      let body = {}
      if(courses?.value === 'all'){
        body = {
          type: 'CAREGIVERS_COURSES',
          caregiverPhone:this.babySitter.info.phone,
          header: '',
          // courseName:'Corsi di formazione Babysitter',
          caregiverName:this.babySitter.info.name,
          courseLink:'Babysitter-1-159573353c6e804f8c64c1724e6e4bcb'
        }
      } else {
        body = {
          type: 'CAREGIVERS_COURSES',
          caregiverPhone:this.babySitter.info.phone,
          header: '',
          // courseName:'Corso metodo Montessori',
          caregiverName:this.babySitter.info.name,
          courseLink:'Babysitter-2-159573353c6e8024945acdd09dbb1767'
        }
      }
      this.http.post(`${environment.cloudFunctionsBaseURL}/sendWhatsapp`, body).pipe(
        tap(() => {
          this.toastService.setAction("Messaggio Whatsapp inviato correttamente!", true);
        }),
        catchError((error) => {
          console.error("Error on sending Whatsapp", error);
          this.toastService.setAction("Errore durante l'invio del messaggio Whatsapp!", false);
          return of();
        } ),
        takeUntil(this.unsubscribe)
      ).subscribe()
    } else {
      this.modalService.showErrorMessage('Attenzone, nessun corso selezionato! Selezionare un corso prima di inviare il messaggio.')
    }
  }

  private publishNewCaregiverScoreUpdate() {
    if (this.caregiverRating) {
      // Insert code to save new caregiver evaluation in the database
      const record: CaregiverEvaluationRecord = {
        ...this.caregiverRating,
        id: null,
        docRef: this.babySitter.docRef,
        operator: this.currentUser,
        timestamp: new Date()
      }
      this.caregiverEvaluationService.addRecord(record).subscribe((response: CaregiverEvaluationRecord) => console.log(record))
      this.caregiverRating = undefined;
    }
  }
  //SCRIPT --- CONVERT ADDRESSES INTO COORDINATES
  private async getCoordiantes(bb: Babysitter) {
    let res = await this.googleMapsService.getCoordinates(bb.info.address).then((res) => {
      const lat = res[0].geometry.location.lat();
      const lng = res[0].geometry.location.lng();
      const coordinates = {
        latitude: lat,
        longitude: lng
      }
      return coordinates
    })
    return res
  }
}
