import { Babysitter, Nurse, Petsitter, Request } from 'npx-family-happy-common';
import { CaregiverSelectionService } from './caregiver-selection-service/caregiver-selection.service';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Caregiver } from 'src/app/shared/models/caregiver.model';
import { RouterStateManagerService } from 'src/app/shared/services/router-state-manager/router-state-manager.service';
import {
  BehaviorSubject,
  interval,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CaregiverSelectionRecordSortable } from 'src/app/shared/models/caregiverSelectionRecordSortable.models';
import { CdkDragDrop, CdkDragMove, DragDropModule, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { RequestService } from '../../request-service/request.service';
import { UntypedFormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AlertComponent } from 'src/app/shared/components/modals/alert/alert.component';
import { BabysitterFilterModalComponent } from 'src/app/shared/components/modals/babysitter-filter-modal/babysitter-filter-modal.component';
import { PetsitterFilterModalComponent } from 'src/app/caregiver/petsitter/petsitter-filter-modal/petsitter-filter-modal.component';
import { NurseFilterModalComponent } from 'src/app/caregiver/nurse/nurse-filter-modal/nurse-filter-modal.component';
import { ColfFilterModalComponent } from 'src/app/caregiver/colf/colf-filter-modal/colf-filter-modal.component';
import { CaregiverCardComponent } from '../caregiver-card/caregiver-card.component';
import { SelectCaregiverModalComponent } from 'src/app/shared/components/modals/select-caregiver-modal/select-caregiver-modal.component';
import { DiscardedAlertComponent } from 'src/app/shared/components/modals/discarded-alert/discarded-alert.component';

@Component({
  selector: 'app-caregiver-selection',
  templateUrl: './caregiver-selection.component.html',
  styleUrls: ['./caregiver-selection.component.scss'],
  standalone: true,
  imports: [CommonModule, AlertComponent, BabysitterFilterModalComponent, PetsitterFilterModalComponent, NurseFilterModalComponent, ColfFilterModalComponent,
    CaregiverCardComponent, SelectCaregiverModalComponent, DiscardedAlertComponent, DragDropModule
  ]
})
export class CaregiverSelectionComponent implements OnInit, OnDestroy {
  @Input() requestCategory!: string;
  @Input() requestID!: number;
  @ViewChild('scrollableElement', { static: true }) scrollableElement!: ElementRef;


  recordSuitable: CaregiverSelectionRecordSortable[] = [];
  recordSelected: CaregiverSelectionRecordSortable[] = [];
  recordChosen: CaregiverSelectionRecordSortable[] = [];
  recordDiscarded: CaregiverSelectionRecordSortable[] = [];

  requestDetail!: Request;
  showAlert = false;
  discarded = false;
  discardAlert = ''
  discardedReasons = ''

  previousContainer: CaregiverSelectionRecordSortable[] = []
  container: CaregiverSelectionRecordSortable[] = []
  previousIndex = -1
  currentIndex = -1
  caregiverRecord: any
  previousContainerID: string = ''
  ContainerID: string = ''

  discard!: boolean;
  actionAlert = '';
  showEngagementALert = false;
  engagementAlert = ''
  showFilters = false;
  filtersObserver = new BehaviorSubject<any>(undefined);
  filters: any;

  originalRecord: CaregiverSelectionRecordSortable[] = [];
  selectedRecord!: CaregiverSelectionRecordSortable

  number = interval(5000)

  caregiver!: Caregiver;
  private unsubscribe = new Subject<void>();

  closeResult = ""
  OldApply = new UntypedFormControl(false)
  toggle = false

  constructor(
    private caregiverSelectionService: CaregiverSelectionService,
    private stateManager: RouterStateManagerService,
    private modalService: NgbModal,
    private requestService: RequestService
  ) {
    this.stateManager
      .getRequestState()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((state) => {
        if (state.request) {
          this.requestDetail = state.request;
        }
      });
    this.caregiverSelectionService.getOldReq().pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
      this.OldApply.setValue(value)
      if (value === true) {
        this.toggle = true
      } else {
        this.toggle = false
      }
    })
  }

  ngOnInit(): void {
    this.updateTabs()
    this.number.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.checkCaregiverEng(this.originalRecord)
    })
    this.OldApply.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
      this.updateTabs()
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  sortOriginalRecord() {
    // Missing sorting on view
    this.updateRecordsLists();
  }

  /* aprire pagina esterna - può essere fatto anche alla fine, non è importante al momento */
  onShowDetailClicked() { }

  /* spostare caregiver dalla lista sorgente a caregiverDiscarded */
  onDiscardClicked(record: CaregiverSelectionRecordSortable) {
    this.selectedRecord = record;
    let entity = this.selectedRecord.record;
    this.discard = true;
    this.actionAlert = 'scartare';

    let caregiver: Babysitter | Petsitter | Nurse | undefined = undefined;
    let role = '';

    if (entity.babysitter) {
      role = 'babysitter';
      caregiver = entity.babysitter;
      this.actionAlert = `Vuoi spostare ${entity.babysitter.info.name} ${entity.babysitter.info.surname} tra i profili scartati?`;
    } else if (entity.petsitter) {
      role = 'petsitter';
      caregiver = entity.petsitter;
      this.actionAlert = `Vuoi spostare ${entity.petsitter.info.name} ${entity.petsitter.info.surname} tra i profili scartati?`;
    } else if (entity.nurse) {
      role = 'nurse';
      caregiver = entity.nurse;
      this.actionAlert = `Vuoi spostare ${entity.nurse.info.name} ${entity.nurse.info.surname} tra i profili scartati?`;
    }
    let discardedType = '';
    if (this.selectedRecord.record.chosen && this.selectedRecord.record.selected && this.selectedRecord.record.suitable) {
      discardedType = 'CHOSEN';
    } else if (this.selectedRecord.record.selected && this.selectedRecord.record.suitable) {
      discardedType = 'SELECTED';
    } else if (this.selectedRecord.record.suitable) {
      discardedType = 'SUITABLE';
    }
    if (caregiver && discardedType) {
      this.requestService.sendEmailDiscarded(caregiver.docRef, caregiver.info.name, caregiver.info.email, role, this.requestID, this.requestDetail.requestPersonalInfo.provincia, discardedType);
    }
  }

  onSubmit(event: boolean) {
    if (event === true) {
      if (this.container === this.recordDiscarded) {
        this.discardAlert = 'Per qual(e/i) motivo(i) la caregiver viene scartata?'
        this.discarded = true;
      } else {
        transferArrayItem(
          this.previousContainer,
          this.container,
          this.previousIndex,
          this.currentIndex,
        );
        this.moveCaregiver(this.caregiverRecord, this.container)
      }
      this.showAlert = false
    } else {
      this.showAlert = false
    }
  }
  onEngagementSubmit(event:boolean){
    if(event === true){
      this.showEngagementALert = false
    }
  }

  onReasonSubmit(reasons: string[]) {
    let string = ''
    reasons.forEach((reason) => {
      string += `${reason}, `
    })
    const lastCommaIndex = string.lastIndexOf(',')
    if (lastCommaIndex !== -1) {
      this.discardedReasons = string.slice(0, lastCommaIndex)
    }
    transferArrayItem(
      this.previousContainer,
      this.container,
      this.previousIndex,
      this.currentIndex,
    );
    this.moveCaregiver(this.caregiverRecord, this.container)
    this.discarded = false
  }

  closeReasonModal(event: boolean) {
    if (event === true) {
      this.discarded = false
    }
  }

  updateAfterAddedCaregiver() {

    this.originalRecord = []
    this.recordDiscarded = []
    this.recordChosen = []
    this.recordSelected = []
    this.recordSuitable = []
    this.updateTabs()
  }

  private updateTabs() {
    this.originalRecord = []
    this.recordSuitable = []
    this.caregiverSelectionService.getCaregiverByRequestID(this.requestID, this.requestDetail.requestPersonalInfo.latitude, this.requestDetail.requestPersonalInfo.longitude, this.OldApply.value)
    .pipe(takeUntil(this.unsubscribe)).subscribe((records) => {
      this.originalRecord = [];
      this.originalRecord = records.map((record) => ({
        record:record,
        fittingIndex:1
      } as CaregiverSelectionRecordSortable))

      this.checkCaregiverEng(this.originalRecord)
      this.updateRecordsLists();
    })
  }

  //CHECK CAREGIVERS ENGAGEMENT METHOD
  checkCaregiverEng(recordArray:CaregiverSelectionRecordSortable[]){
    let caregiverIds = recordArray.map(item => this.takeDocRefFromRecord(item)) as string[]
    this.caregiverSelectionService.checkCaregiverEngagementIds(caregiverIds, this.requestID).pipe(takeUntil(this.unsubscribe)).subscribe((records) => {
      this.originalRecord=[... this.originalRecord.map((record) => {
        const index = records.findIndex((item) => {return item.caregiverId === this.takeDocRefFromRecord(record)})
        if(index > -1){
          record.idCheck = records[index];
        }
        return record
      })]
    });
  }

  takeDocRefFromRecord(record:CaregiverSelectionRecordSortable){
    return record.record.babysitter?.docRef?? record.record.petsitter?.docRef?? record.record.nurse?.docRef?? record.record.colf?.docRef;
  }

  openFilters() {
    this.showFilters = true;
    this.filtersObserver.next(this.filters)
  }
  processFilter() {
    this.caregiverSelectionService.setFilters(this.filters, this.requestCategory, true);
    this.showFilters = false;
  }

  //DRAG AND DROP
  moveCaregiver(caregiver: CaregiverSelectionRecordSortable, array: CaregiverSelectionRecordSortable[]) {
    this.selectedRecord = caregiver
    if (array === this.recordSuitable) {
      this.selectedRecord.record.suitable = true;
      this.selectedRecord.record.selected = false;
      this.selectedRecord.record.chosen = false;
      this.selectedRecord.record.discarded = false;
      this.caregiverSelectionService
        .updateCaregiverSelectionCategory(this.selectedRecord.record, 'SUITABLE', this.discardedReasons)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((record) => {
          this.selectedRecord.record.suitable = record.suitable;
          this.selectedRecord.record.selected = record.selected;
          this.selectedRecord.record.chosen = record.chosen;
          this.selectedRecord.record.discarded = record.discarded;
          this.updateRecordsLists()
          return record });
      this.recordSuitable.sort((a, b) => b.fittingIndex - a.fittingIndex);
    }
    if (array === this.recordSelected) {
      this.selectedRecord.record.suitable = false;
      this.selectedRecord.record.selected = true;
      this.selectedRecord.record.chosen = false;
      this.selectedRecord.record.discarded = false;
      this.checkEngagement(this.selectedRecord, this.recordChosen)
      this.caregiverSelectionService
        .updateCaregiverSelectionCategory(this.selectedRecord.record, 'SELECTED', this.discardedReasons)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((record) => {
          this.selectedRecord.record.suitable = record.suitable;
          this.selectedRecord.record.selected = record.selected;
          this.selectedRecord.record.chosen = record.chosen;
          this.selectedRecord.record.discarded = record.discarded;
          this.updateRecordsLists()
          return record
        });
      this.recordSelected.sort((a, b) => b.fittingIndex - a.fittingIndex);
    }
    if (array === this.recordChosen) {
      this.selectedRecord.record.suitable = false;
      this.selectedRecord.record.selected = false;
      this.selectedRecord.record.chosen = true;
      this.selectedRecord.record.discarded = false;
      this.checkEngagement(this.selectedRecord, this.recordChosen)
      this.caregiverSelectionService
        .updateCaregiverSelectionCategory(this.selectedRecord.record, 'CHOSEN', this.discardedReasons)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((record) => {
          this.selectedRecord.record.suitable = record.suitable;
          this.selectedRecord.record.selected = record.selected;
          this.selectedRecord.record.chosen = record.chosen;
          this.selectedRecord.record.discarded = record.discarded;
          this.updateRecordsLists()
          return record
        });
      this.recordChosen.sort((a, b) => b.fittingIndex - a.fittingIndex);
    }
    if (array === this.recordDiscarded) {
      this.selectedRecord.record.suitable = false;
      this.selectedRecord.record.selected = false;
      this.selectedRecord.record.chosen = false;
      this.selectedRecord.record.discarded = true;
      this.caregiverSelectionService
        .updateCaregiverSelectionCategory(this.selectedRecord.record, 'DISCARDED', this.discardedReasons)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((record) => {
          this.selectedRecord.record.suitable = record.suitable;
          this.selectedRecord.record.selected = record.selected;
          this.selectedRecord.record.chosen = record.chosen;
          this.selectedRecord.record.discarded = record.discarded;
          this.onDiscardClicked(this.selectedRecord)
          this.updateRecordsLists()
          return record });
      this.recordDiscarded.sort((a, b) => b.fittingIndex - a.fittingIndex);
    }
  }

  drop(event: CdkDragDrop<CaregiverSelectionRecordSortable[]>) {
    this.caregiverRecord = event.item.data
    this.container = event.container.data
    this.previousContainerID = event.previousContainer.id
    this.ContainerID = event.container.id
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      const previousID = +this.previousContainerID[this.previousContainerID.length - 1];
      const containerID = +this.ContainerID[this.ContainerID.length - 1];
      if(previousID !== 3){
        this.generateAlertMessage(this.caregiverRecord, this.container, previousID, containerID);
        this.previousContainer = event.previousContainer.data;
        this.container = event.container.data;
        this.previousIndex = event.previousIndex;
        this.currentIndex = event.currentIndex;
        this.showAlert = true;
      } else {
        if(!this.caregiverRecord.record.cvSent && !this.caregiverRecord.record.contactSent) {
          this.generateAlertMessage(this.caregiverRecord, this.container, previousID, containerID);
          this.previousContainer = event.previousContainer.data;
          this.container = event.container.data;
          this.previousIndex = event.previousIndex;
          this.currentIndex = event.currentIndex;
          this.showAlert = true;
        }
      }
    }
  }

  private generateAlertMessage(caregiver: CaregiverSelectionRecordSortable, array: CaregiverSelectionRecordSortable[], previousID: number, containerID: number) {
    let caregiverName = ''
    if (caregiver.record.babysitter) {
      caregiverName = `${caregiver.record.babysitter.info.name} ${caregiver.record.babysitter.info.surname}`
    } else if (caregiver.record.petsitter) {
      caregiverName = `${caregiver.record.petsitter.info.name} ${caregiver.record.petsitter.info.surname}`
    } else if (caregiver.record.nurse) {
      caregiverName = `${caregiver.record.nurse.info.name} ${caregiver.record.nurse.info.surname}`
    } else if (caregiver.record.colf) {
      caregiverName = `${caregiver.record.colf.info.name} ${caregiver.record.colf.info.surname}`
    }

    if (previousID === 3) {
      switch (containerID) {
        case 0:
          this.actionAlert = `Sei sicuro di voler spostare ${caregiverName} tra i profili candidati?`;
          break;
        case 1:
          this.actionAlert = `Sei sicuro di voler spostare ${caregiverName} tra i profili selezionati?`;
          break;
        case 2:
          this.actionAlert = `Sei sicuro di voler spostare ${caregiverName} tra i profili scelti?`;
          break;
        default:
          break;
      }
      if (caregiver.record.discarded && (caregiver.record.cvSent || caregiver.record.contactSent)) {
        this.actionAlert = this.actionAlert + ` E' già stata presentata alla famiglia.`;
      }
      if (caregiver.record.discardedReason) {
        this.actionAlert = this.actionAlert + ` In precedenza era stata scartata per '${caregiver.record.discardedReason}'.`;
      }
    } else if (previousID > containerID) {
      switch (containerID) {
        case 0:
          this.actionAlert = `Sei sicuro di voler riportare ${caregiverName} tra i profili candidati?`;
          break;
        case 1:
          this.actionAlert = `Sei sicuro di voler riportare ${caregiverName} tra i profili selezionati?`;
          break;
        case 2:
          this.actionAlert = `Sei sicuro di voler riportare ${caregiverName} tra i profili scelti?`;
          break;
        default:
          break;
      }
    } else {
      if (array === this.recordSelected) {
        this.actionAlert = `Vuoi spostare ${caregiverName} tra i profili selezionati?`;
      } else if (array === this.recordChosen) {
        this.actionAlert = `Vuoi spostare ${caregiverName} tra i profili candidati?`;
      } else if (array === this.recordDiscarded) {
        this.actionAlert = `Vuoi spostare ${caregiverName} tra i profili scartati?`;
      }
    }
  }

  checkEngagement(record:CaregiverSelectionRecordSortable, array:CaregiverSelectionRecordSortable[]){
    let id = ''
    let status = ''
    if(array === this.recordSelected){
      status = 'selezionata'
    } else if(array === this.recordChosen){
      status = 'scelta'
    }
    if(record.record.babysitter?.docRef){
      id = record.record.babysitter?.docRef
      this.engagementAlert = `la babysitter è già stata ${status} per un'altra richiesta`
    } else if (record.record.petsitter?.docRef){
      id = record.record.petsitter?.docRef
      this.engagementAlert = `la petsitter è già stata ${status} per un'altra richiesta`
    } else if (record.record.nurse?.docRef){
      id = record.record.nurse?.docRef
      this.engagementAlert = `la badante è già stata ${status} per un'altra richiesta`
    } else if (record.record.colf?.docRef){
      id = record.record.colf?.docRef
      this.engagementAlert = `la colf è già stata ${status} per un'altra richiesta`
    }
    this.caregiverSelectionService.checkCaregiverEngagement(id).pipe(takeUntil(this.unsubscribe)).subscribe((res) =>{
      if(res){
        if(res.result === true){
          this.showEngagementALert = true
        } else {
          this.showEngagementALert = false
        }
      }
    })
  }

  filterOld() {
    this.caregiverSelectionService.setOldReq(!this.OldApply.value)
  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }

  private updateRecordsLists() {
    this.originalRecord.sort((a, b) => b.fittingIndex - a.fittingIndex);
    // Reset records lists
    this.recordSuitable = []
    this.recordChosen = []
    this.recordSelected = []
    this.recordDiscarded = []
    // Populate records lists
    this.originalRecord.forEach((recordSortable) => {
      if (recordSortable.record.discarded) {
        this.recordDiscarded.push(recordSortable);
      } else if (recordSortable.record.chosen) {
        this.recordChosen.push(recordSortable);
      } else if (recordSortable.record.selected) {
        this.recordSelected.push(recordSortable);
      } else if (recordSortable.record.suitable) {
        this.recordSuitable.push(recordSortable);
      }
    })
  }

  //scroll-x
  get window(): Window {
    return window;
  }
  scrollX(event: CdkDragMove) {
    let windowWidth = this.window.innerWidth;
    let position = event.pointerPosition;
    let container = this.scrollableElement.nativeElement;
    if (position.x >= windowWidth) {
      container!.scrollLeft += position.x - windowWidth
    } else if (position.x <= 0) {
      container!.scrollLeft += position.x - windowWidth
    }
  }
}

