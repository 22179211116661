<div class="bigger-container">
  <div class="left-section">
    <p [ngClass]="{'selected': step === 0}" (click)="changeTab(0)">Informazioni Richiesta</p>
    <p [ngClass]="{'selected': step === 1}" (click)="changeTab(1)">Dettagli Richiesta</p>
    <p [ngClass]="{'selected': step === 2}" (click)="changeTab(2)">Stato Richiesta</p>
    <p [ngClass]="{'selected': step === 3}" (click)="changeTab(3)">Messaggi Richiesta</p>
    <p [ngClass]="{'selected': step === 4}" (click)="changeTab(4)">Cronologia operazioni</p>
    <p [ngClass]="{'selected': step === 5}" (click)="changeTab(5)">Feedback cliente</p>
  </div>

  <div class="right-section" *ngIf="request">
    <div class="back-button" (click)="goBack()">
      <i class="material-icons">arrow_back</i>
      <p>Torna alla lista</p>
    </div>
    <div class="smaller-container">
      <p class="smaller-text">{{isNew ? 'Crea' : 'Modifica'}} Richiesta {{ isNew && request ? '' : 'n. ' + request.id
        }}
      </p>
      <div class="smaller-bar"></div>
    </div>
    <p class="my-button" *ngIf="updateRequired" (click)="isNew ? createRequest() : updateRequest(true)">
      Salva
    </p>
    <!-- Section containing the script aimed to help the Family Tutor -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title><b>Script</b></mat-panel-title>
      </mat-expansion-panel-header>
      <div
        [innerHTML]="script">
      </div>
    </mat-expansion-panel>

    <div class="mt-3" *ngIf="request">
      <!-- Request Operational Info -->
      <app-request-personal-info *ngIf="step === 0" [requestID]="request.id" [inputData]="request.requestPersonalInfo"
        [requestCategory]="request.requestPersonalInfo.requestCategory"
        (dataUpdated)="updateRequestPersonalInfo($event)" (addressUpdated)="updateCoordinates($event)"></app-request-personal-info>
      <!-- Request Detail for Babysitters -->
      <app-request-babysitter-detail-form
        *ngIf="step === 1 && request.requestPersonalInfo.requestCategory === 'BABYSITTER'" [requestID]="request.id"
        [inputData]="request.requestDetail" [provincia]="request.requestPersonalInfo.provincia" [reqService]="reqService"
        (dataUpdated)="updateRequestDetailInfo($event)"></app-request-babysitter-detail-form>
      <!-- Request Detail for Petsitters -->
      <app-request-petsitter-detail-form
        *ngIf="step === 1 && request.requestPersonalInfo.requestCategory === 'PETSITTER'" [requestID]="request.id"
        [inputData]="petsitterDetail" [provincia]="request.requestPersonalInfo.provincia" [reqService]="reqService"
        (dataUpdated)="updatePetsitterRequestDetailInfo($event)"></app-request-petsitter-detail-form>
      <!-- Request Detail for Nurse -->
      <app-request-nurse-detail-form *ngIf="step === 1 && request.requestPersonalInfo.requestCategory === 'NURSE'"
        [requestID]="request.id" [inputData]="nurseDetail" [provincia]="request.requestPersonalInfo.provincia" [reqService]="reqService"
        (dataUpdated)="updateNurseRequestDetailInfo($event)"></app-request-nurse-detail-form>
        <!-- Request Detail for Colf -->
        <app-request-colf-detail-form *ngIf="step === 1 && request.requestPersonalInfo.requestCategory === 'COLF'"
        [requestID]="request.id" [inputData]="colfDetail" [provincia]="request.requestPersonalInfo.provincia" [reqService]="reqService"
        (dataUpdated)="updateColfRequestDetailInfo($event)"
        ></app-request-colf-detail-form>
      <!-- Request Operational Info -->
      <app-request-operational-info *ngIf="step === 2" [requestID]="request.id" [inputData]="request.operationalInfo"
        [requestCategory]="request.requestPersonalInfo.requestCategory"
        (dataUpdated)="updateRequestOperationalInfo($event)"></app-request-operational-info>
      <!-- Request Messages -->
      <app-request-messages [requestID]="request.id" *ngIf="step === 3"></app-request-messages>
      <!-- Request Updates -->
      <app-request-updates *ngIf="step === 4" [requestID]="request.id"></app-request-updates>
      <!-- nps -->
       <app-request-nps *ngIf="step === 5" [request]="request"></app-request-nps>
    </div>
  </div>
</div>

<app-alert *ngIf="showAlert" (save)="onSubmit($event)" [data]="{ type: undefined }"
  [title]="'Attenzione, alcuni dati sono stati modificati!'" [message]="'Vuoi salvare le modifiche effettuate?'">
</app-alert>
