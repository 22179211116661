import { Observable } from 'rxjs';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CaregiverSelectionService } from 'src/app/requests-handling/caregiver-scoreboard/caregiver-selection/caregiver-selection-service/caregiver-selection.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nurse-filter-modal',
  templateUrl: './nurse-filter-modal.component.html',
  styleUrls: ['./nurse-filter-modal.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule]
})
export class NurseFilterModalComponent implements OnInit {

  private _resetAllFilters: boolean = false;

  @Input() filtersValue!: Observable<any>;
  @Input() showAgeFilter: boolean = false;
  @Input() isRequestDetail!:boolean
  @Output() onClose = new EventEmitter<void>();
  @Output() onFilter = new EventEmitter<any>();
  filters!: UntypedFormGroup;

  @Input()
  get resetAllFilters(): boolean {
    return this._resetAllFilters;
  }
  set resetAllFilters(value: boolean) {
    this._resetAllFilters = value;
    if(this._resetAllFilters === true){
      this.caregiverSelectionService.setFilters(null, 'NURSE', this.isRequestDetail)
      this.resetFilters();
    }
  }

  constructor(private caregiverSelectionService: CaregiverSelectionService) { }

  ngOnInit(): void {
    this.filters = new UntypedFormGroup({
      specialities: new UntypedFormGroup({
        injections: new UntypedFormControl(false),
        dressings: new UntypedFormControl(false),
        glycemia: new UntypedFormControl(false),
        technology: new UntypedFormControl(false),
      }),
      roles: new UntypedFormGroup({
        dayShift: new UntypedFormControl(false),
        nightShift: new UntypedFormControl(false),
        changing: new UntypedFormControl(false),
        cleaning: new UntypedFormControl(false),
        hospital: new UntypedFormControl(false),
        taxi: new UntypedFormControl(false),
        taxiNoCar: new UntypedFormControl(false),
        walking: new UntypedFormControl(false),
        coliving: new UntypedFormControl(false),
      }),
      rating: new UntypedFormControl(false),
      license: new UntypedFormControl(false),
      car: new UntypedFormControl(false),
      fhsub: new UntypedFormControl(false),
      minAge: new UntypedFormControl(0),
      maxAge: new UntypedFormControl(0),
      page: new UntypedFormControl(1),
      days: new UntypedFormGroup({
        sun: new UntypedFormControl(false),
        mon: new UntypedFormControl(false),
        tue: new UntypedFormControl(false),
        wed: new UntypedFormControl(false),
        thu: new UntypedFormControl(false),
        fri: new UntypedFormControl(false),
        sat: new UntypedFormControl(false),
      }),
      hours: new UntypedFormGroup({
        mor: new UntypedFormControl(false),
        aft: new UntypedFormControl(false),
        eve: new UntypedFormControl(false),
      }),
      contract: new UntypedFormGroup({
        occ: new UntypedFormControl(false),
        part: new UntypedFormControl(false),
        full: new UntypedFormControl(false),
      }),
      lang: new UntypedFormGroup({
        english: new UntypedFormControl(false),
        italian: new UntypedFormControl(false),
        spanish: new UntypedFormControl(false),
        englishAdvanced: new UntypedFormControl(false),
        italianAdvanced: new UntypedFormControl(false),
        spanishAdvanced: new UntypedFormControl(false),
      }),
      exp: new UntypedFormGroup({
        cluster1: new UntypedFormControl(false),
        cluster2: new UntypedFormControl(false),
      }),
      distance: new UntypedFormControl(null)
    });
    this.caregiverSelectionService.getFilters('NURSE', this.isRequestDetail)!.subscribe((filters) => {
      if (filters !== undefined && filters !== null) {
        if (Object.keys(filters).length > 0) {
          this.filters.setValue(filters);
        } else {
          this.filters.reset();
        }
      }
    });
  }

  /* Close the modal */
  dismiss(event: Event) {
    if (event.target === document.getElementById('modal')) {
      this.onClose.emit();
    }
  }

  emitFilter() {
    this.caregiverSelectionService.setFilters(this.filters.value, 'NURSE', this.isRequestDetail);
    this.onFilter.emit(this.filters.value);
    this.onClose.emit()
  }

  resetFilters() {
    if(this.filters !== undefined){
      this.filters.reset();
      this.caregiverSelectionService.setFilters(this.filters.value, 'NURSE', this.isRequestDetail);
    }
  }
}
