import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Request } from 'npx-family-happy-common';

@Injectable({
  providedIn: 'root'
})
export class NpsTrackingService {

  private baseURL = environment.npsScoreAPI

  constructor(private http:HttpClient) { }

  getReqNPS(requestId:number){
    return this.http.get<any>(`${this.baseURL}/request/${requestId}`)
  }

  createNPS(request:Request, score:number, notes:string | null){
    let body = {
      id:null,
      score: score,
      requestID: request.id,
      email:request.requestPersonalInfo.parentEmail,
      timestamp: new Date(),
      notes: notes
    }
    return this.http.post<any>(`${this.baseURL}`, body)
  }

}
