import { VariableSharingService } from './../../../shared/components/text-editor/text-editor-service/text-editor-service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FHService, MailTemplate, ModalService } from 'npx-family-happy-common';
import { Subject, takeUntil } from 'rxjs';
import { EmailTemplateService } from '../email-services/email.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FHServiceSelectComponent } from 'src/app/shared/components/custom-select/fh-service-select/fh-service-select.component';
import { TextEditorComponent } from 'src/app/shared/components/text-editor/text-editor.component';
import { AlertComponent } from 'src/app/shared/components/modals/alert/alert.component';

@Component({
  selector: 'app-email-detail',
  templateUrl: './email-detail.component.html',
  styleUrls: ['./email-detail.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FHServiceSelectComponent, TextEditorComponent, AlertComponent]
})
export class EmailDetailComponent implements OnInit, OnDestroy {

  @ViewChild('textInput') textInput!: any;

  form = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    lang: new UntypedFormControl(null, [Validators.required]),
    subject: new UntypedFormControl(null, [Validators.required]),
    text: new UntypedFormControl(null, [Validators.required]),
    type: new UntypedFormControl(null, [Validators.required]),
    service: new UntypedFormControl(null),
    templateServiceBusinessLine: new UntypedFormControl(null, [Validators.required]),
    templateServiceCustomerType: new UntypedFormControl(null, [Validators.required]),
    templateServiceServiceName: new UntypedFormControl(null, [Validators.required]),
    templateServiceID: new UntypedFormControl(null),
    templateServiceScript: new UntypedFormControl(''),
    templateServiceDisabled: new UntypedFormControl(null)
  });

  editorTitle = "Email Template"

  selectedTemplate: MailTemplate | undefined;
  isNew: boolean = false;
  showDeleteConfirm: boolean = false;
  showVariableList: boolean = false;
  eMailText!: string;
  eventTest: MouseEvent | undefined;
  closeResult = '';
  cursorPosition: number | null = null
  readOnly = true

  private unsubscribe = new Subject<void>();
  localVariable = ''
  textFormTemplate = ''
  formInvalid = false
  isDirty = false //for validators editor component
  haveText = false //for validators editor component

  constructor(
    private router: Router,
    private emailTemplateService: EmailTemplateService,
    private modalService: ModalService,
    private modalServiceNGB: NgbModal,
    private activatedRoute: ActivatedRoute,
    private variableSharingService: VariableSharingService
  ) {
  }

  saveTemplate() {
    if (this.form.valid) {
      const formValue = this.form.value;
      const template: MailTemplate = {
        id: formValue.id,
        lang: formValue.lang,
        subject: formValue.subject,
        text: formValue.text,
        type: formValue.type,
        service: {
          id: formValue.templateServiceID,
          businessLine: formValue.templateServiceBusinessLine,
          customerType: formValue.templateServiceCustomerType,
          name: formValue.templateServiceServiceName,
          script: '',
          disabled: formValue.templateServiceDisabled
        },
      };
      this.emailTemplateService
        .saveTemplate(template)
        .subscribe(() =>
          this.modalService.showSuccessMessage('Template aggiornato')
        );
    } else {
      this.isDirty = true
      this.haveText = false
      this.formInvalid = true
    }
  }
  deleteTemplate() {
    this.showDeleteConfirm = true;
  }
  confirmDelete(answer: boolean) {
    if (answer && this.selectedTemplate && this.selectedTemplate.id > -1) {
      this.emailTemplateService.deleteTemplateByID(this.selectedTemplate.id).subscribe(() => {
        this.modalService.showSuccessMessage('Template eliminato');
        this.goBackToEmails();
      });
    }
    this.showDeleteConfirm = false;
  }
  validatorErrorMessage(fieldName: string) {
    const field = this.form.get(fieldName);
    let message = ''
    if (field?.errors) {
      if (field?.errors['required']) {
        message = `Questo campo è obbligatorio`
      }
    }
    return message
  }

  goBackToEmails() {
    this.router.navigate(['/emails']);
  }
  updateFHService(service: FHService | undefined) {
    if (service === undefined) {
      this.form.controls.templateServiceBusinessLine.reset();
      this.form.controls.templateServiceCustomerType.reset();
      this.form.controls.templateServiceServiceName.reset();
      this.form.controls.templateServiceID.reset();
      this.form.controls.templateServiceScript.reset();
      this.form.controls.templateServiceDisabled.reset()
    } else {
      this.form.controls.templateServiceBusinessLine.setValue(
        service?.businessLine
      );
      this.form.controls.templateServiceCustomerType.setValue(
        service?.customerType
      );
      this.form.controls.templateServiceServiceName.setValue(service?.name);
      this.form.controls.templateServiceID.setValue(service?.id);
      this.form.controls.templateServiceScript.setValue(service?.script);
      this.form.controls.templateServiceDisabled.setValue(service?.disabled)
    }
  }
  initForm(template: MailTemplate) {
    this.form.setValue({
      ...template,
      lang: template.lang,
      type: template.type,
      subject: template.subject,
      text: template.text,
      templateServiceBusinessLine: template.service.businessLine,
      templateServiceCustomerType: template.service.customerType,
      templateServiceServiceName: template.service.name,
      templateServiceID: template.service.id,
      templateServiceScript: template.service.script,
      templateServiceDisabled: template.service.disabled
    });
    this.haveText = true
    this.eMailText = this.form.controls.text.value;
  }

  saveText(event: string) {
    this.isDirty = true
    this.haveText = true
    this.form.controls.text.setValue(event)
  }
  ngOnInit(): void {
    this.activatedRoute.paramMap
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((param) => {
        const id = param.get('id') ?? -1;
        if (id === 'create') {
          this.isNew = true;
        } else {
          if (id === -1) {
            this.goBackToEmails();
          } else {
            this.emailTemplateService
              .getTemplateByID(+id).pipe(takeUntil(this.unsubscribe))
              .subscribe((Template: MailTemplate) => {
                this.selectedTemplate = Template;
                this.initForm(Template);
              });
          }
        }
      });
    // this.variableLocal$ = this.variableLocalSource.asObservable();
    // this.pushObserableVariable();
  }
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  open(content: any) {
    this.modalServiceNGB.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }
  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }
}


