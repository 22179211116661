import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, catchError, of, takeUntil, tap } from 'rxjs';
import { ToastComponent } from '../../components/toast/toast.component';

export interface Toast {
	template: TemplateRef<any>;
	classname?: string;
	delay?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toasts: Toast[] = [];
  toasts$ = new BehaviorSubject<Toast[]>([])
  action$ = new BehaviorSubject<String>('')
  private toastComponent = new BehaviorSubject<ToastComponent | null>(null);
  toast!: ToastComponent | null;

  constructor() { }


	show(toast: Toast) {
		this.toasts.push(toast);
    this.toasts$.next(this.toasts)
	}

	remove(toast: Toast) {
		this.toasts = this.toasts.filter((t) => t !== toast);
    this.toasts$.next(this.toasts)
	}

	clear() {
		this.toasts.splice(0, this.toasts.length);
    this.toasts$.next(this.toasts)
	}

  getToast(){
    return this.toasts$.asObservable()
  }

  setAction(action:string, isSuccess:boolean){
    this.action$.next(action)
    this.getChildComponent().pipe(
      tap((component) => {
        this.toast = component
        if(isSuccess === true) this.toast!.showSuccess(this.toast!.successTpl)
        if(isSuccess === false) this.toast!.showDanger(this.toast!.dangerTpl)
      }),
    catchError((err) => {
      console.error(err, "error on Toast setAction serivce")
      return of()
    })
    ).subscribe()
  }

  getAction(){
    return this.action$.asObservable()
  }

  setChildComponent(component: ToastComponent) {
    this.toastComponent.next(component)
  }

  getChildComponent() {
    return this.toastComponent.asObservable();
  }
}
